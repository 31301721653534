import React, { FC, useCallback, useContext, useState } from "react";
import validate from "validate.js";
import { miscContext } from "../../../state/miscState";
import { toastSubject } from "../../../state/rxjs";
import { searchContext } from "../../../state/search/searchState";
import { useKeys } from "../../../utils/hooks";
import { constructFiltersQuery } from "../../../utils/query";
import { Button } from "../../common/button/Button";
import { Input } from "../../common/input/Input";
import { validator } from "../../common/input/validator";
import { ModalProps } from "../Modals";
import "./SaveSearch.css";

export const SaveSearch: FC<ModalProps> = ({ showModal, closeModal }) => {
  const formName = "SaveSearch";

  const miscState = useContext(miscContext);
  const searchState = useContext(searchContext);

  const [name, setName] = useState("");
  const submit = () => {
    validator
      .validate("SaveSearch", true)
      .then(() => {
        miscState.addSearch(getUrl(), name);
        toastSubject.next("Search saved");
        closeModal();
      })
      .catch(() => 0);
  };

  const getUrl = useCallback(() => {
    let searchQuery = constructFiltersQuery(searchState.filters);
    if (searchQuery.length === 0) {
      return "";
    }
    return searchQuery;
  }, [searchState.filters]);

  useKeys(["Enter"], [submit], !showModal);

  return (
    <>
      <div>
        <h3>Enter search name</h3>
        <Input
          value={name}
          onChange={e => {
            setName(e.currentTarget.value);
          }}
          label="Search name"
          name="searchName"
          formName={formName}
          validateFunc={() =>
            validate.single(name, { presence: { allowEmpty: false } })
          }
        />
      </div>

      <div className="modal-actions">
        <Button onClick={closeModal} className="btn btn-gray">
          Close
        </Button>
        <Button onClick={submit} className="btn btn-success">
          Save
        </Button>
      </div>
    </>
  );
};
