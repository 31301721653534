import React, { useContext } from 'react';
import { filesContext } from '../../../state/filesState';
import { CustomScrollbars } from '../../common/scrollbars/Scrollbars';
import { Checkbox } from '../../common/checkbox/Checkbox';
import { Spinner } from '../../common/spinner/Spinner';
import { observer } from 'mobx-react';
import moment from 'moment';

export const Cases = observer(() => {
  const filesState = useContext(filesContext);

  return (
    <div className="cases">
      <h2>Cases</h2>
      <button className="btn-link" onClick={filesState.clearFilter}>
        Clear filter
      </button>
      <div className="cases-scrollbox scrollbar-fit-container">
          <CustomScrollbars
            style={{}}
            scrollbarStyle={{ right: '-16px' }}
            shadow={true}
            translateContentSize={false}
            disable={window.innerWidth < 768}
          >
            <div className="cases-checkboxes" style={{ position: 'relative' }}>
              {filesState.cases.map((caze) => {
                return (
                  <Checkbox
                    inverse={true}
                    tooltip={
                      caze.clientReference +
                      (caze.warrant &&
                      (caze.warrant.warrantDate ||
                        caze.warrant.liabilityOrderDate ||
                        caze.warrant.offenceDate)
                        ? ' - ' +
                          moment(
                            caze.warrant.warrantDate ||
                              caze.warrant.liabilityOrderDate ||
                              caze.warrant.offenceDate
                          ).format('MMYY')
                        : '')
                    }
                    label={
                      <span className="case-row">
                        <span className="case-ref">{caze.clientReference}</span>
                        {caze.warrant &&
                        (caze.warrant.warrantDate ||
                          caze.warrant.liabilityOrderDate ||
                          caze.warrant.offenceDate) ? (
                          <span className="case-date">
                            {' - '}
                            {moment(
                              caze.warrant.warrantDate ||
                                caze.warrant.liabilityOrderDate ||
                                caze.warrant.offenceDate
                            ).format('MMYY')}
                          </span>
                        ) : (
                          ''
                        )}
                      </span>
                    }
                    checked={filesState.selectedCases.has(caze)}
                    onChange={(e) =>
                      filesState.toggleCase(e.currentTarget.checked, caze)
                    }
                    key={
                      'case-' + caze.clientReference + caze.id + caze.oneStepId
                    }
                  />
                );
              })}

              {filesState.loadingCases ? <Spinner blocking={true} /> : null}
            </div>
          </CustomScrollbars>
      </div>
    </div>
  );
});
