import { action } from "mobx";
import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useContext } from "react";
import { DebtorDto } from "../../../server-api/model";
import { actionsContext } from "../../../state/actionsState";
import { debtorContext } from "../../../state/debtorState";
import { toastSubject } from "../../../state/rxjs";
import { Input } from "../../common/input/Input";
import { InputSelect } from "../../common/input/InputSelect";
import { TextField } from "../../common/input/TextField";
import { validator } from "../../common/input/validator";
import { Spinner } from "../../common/spinner/Spinner";
import { ModalProps } from "../Modals";
import { SelectInfo } from "./selectinfo/SelectInfo";
import { ActionSubmit } from "./submit/ActionSubmit";
import validate from "validate.js";

export const MessageJBW: FC<ModalProps> = observer(
  ({ showModal, closeModal }) => {
    const AS = useContext(actionsContext);
    const debtorState = useContext(debtorContext);

    const formName = "MessageJBW";

    let debtor: DebtorDto | undefined;
    if (AS.selectedCases.size > 0) {
      debtor = undefined;
    } else if (AS.selectedDebtors.size > 0) {
      debtor = AS.selectedDebtors.keys().next().value;
    } else if (debtorState.loadedDebtor) {
      debtor = debtorState.loadedDebtor;
    }

    const changeType = action((value: string) => {
      AS.messageType = value;
    });

    const changeMessage = action((e: React.FormEvent<HTMLTextAreaElement>) => {
      AS.messageMessage = e.currentTarget.value;
    });

    const changeSubject = action((e: React.FormEvent<HTMLInputElement>) => {
      AS.messageSubject = e.currentTarget.value;
    });

    const submit = useCallback(() => {
      if (AS.submitting) {
        return;
      }

      validator.validate(formName, true).then(() => {
        AS.submitMessage(debtor ? debtor.id : undefined)
          .then(() => {
            toastSubject.next(
              "Your message has been sent to " + window.COMPANY_LABEL + "."
            );
            closeModal();
          })
          .catch(err => {
            toastSubject.next(err.message);
          });
      }).catch(()=>0);;
    }, [AS, formName, closeModal, debtor]);

    if (!showModal) {
      return null;
    }

    return (
      <>
        {AS.enquiryCategories ? null : <Spinner blocking={true} />}
        <div>
          <SelectInfo debtor={debtor} />
          <h3>Message details</h3>
          <InputSelect
            formName={formName}
            placeholder={"Select type"}
            options={AS.enquiryCategories}
            label="Message type"
            name="message-type"
            selectedThing={AS.messageType}
            onChange={changeType}
            validateFunc={() =>
              validate.single(AS.messageType, {
                presence: { allowEmpty: false, message: "Type is required" }
              })
            }
          />
          <Input
            formName={formName}
            onChange={changeSubject}
            label="Subject"
            name="subject"
            placeholder="Add a subject"
            value={AS.messageSubject}
            validateFunc={() =>
              validate.single(AS.messageSubject, {
                presence: { allowEmpty: false }
              })
            }
          />
          <h3>Message</h3>
          <TextField
            formName={formName}
            placeholder="Add a message"
            name="message"
            onChange={changeMessage}
            value={AS.messageMessage}
            className="wide"
            validateFunc={() =>
              validate.single(AS.messageMessage, {
                presence: { allowEmpty: false }
              })
            }
          />
        </div>
        <ActionSubmit
          submitting={AS.submitting}
          cancel={closeModal}
          submit={submit}
          submitText="Send message"
        />
      </>
    );
  }
);
