import { Button } from 'components/common/button/Button';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { __RouterContext } from 'react-router';
import validate from 'validate.js';
import { EXPIRED, TWO_FACTOR } from '../../routes';
import { profileContext } from '../../state/profileState';
import { Input } from '../common/input/Input';
import { InputPassword } from '../common/input/InputPassword';
import { validator } from '../common/input/validator';
import { Spinner } from '../common/spinner/Spinner';

export const LoginForm = observer(() => {
  const ref = useRef<HTMLFormElement>(null);
  const profileState = useContext(profileContext);
  const routerState = useContext(__RouterContext);
  const [badCredentials, setBadCredentials] = useState(false);
  const formName = 'login';
  const resetPassword = () => {
    profileState.requestPasswordReset(username);
  };

  const resetPasswordKeyDown = (e: React.KeyboardEvent) => {
    if (e.code == 'Enter' || e.code == 'Space') {
      e.preventDefault();
      resetPassword();
    }
  }

  const doLogin = (e?: FormEvent<HTMLFormElement> | React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    try {
      (window.external as any).AutoCompleteSaveForm(
        ref.current!.cloneNode(true)
      );
    } catch (error) {
      // https://github.com/facebook/react/issues/12749
    }
    validator
      .validate(formName, true)
      .then(() => {
        profileState.login().then((res) => {
          if (res === 'SUCCESS') {
            routerState.history.push('/');
          } else if (res === 'EXPIRED') {
            routerState.history.push(EXPIRED);
          } else if (res === 'TWO_FACTOR') {
            routerState.history.push(TWO_FACTOR);
          } else if (res === 'BAD_CREDENTIALS') {
            setBadCredentials(true);
          }
        });
      })
      .catch(() => 0);
  };

  const changeUsername = (name: string) => {
    runInAction(() => {
      profileState.username = name;
    });
  };

  const changePassword = (pass: string) => {
    runInAction(() => {
      profileState.password = pass;
    });
  };

  useEffect(() => {
    runInAction(() => {
      if (profileState.passwordExpiredLogin) {
        profileState.username = profileState.passwordExpiredLogin;
      }
    });
  }, [profileState.passwordExpiredLogin]);

  useEffect(() => {
    if (badCredentials) {
      validator.validate(formName);
      setBadCredentials(false);
    }
  }, [badCredentials]);

  //useKeys(["Enter"], [doLogin]); // not needed for <form>

  const { username, password } = profileState;

  return (
    <form className="login-form" action="#" onSubmit={doLogin} ref={ref}>
      <div role='group' aria-labelledby='clientPortalLabel'>
        <label htmlFor="username-input">Username</label>
        <Input
          width="wide"
          formName={formName}
          validateFunc={() => {
            if (badCredentials) {
              return ['Incorrect username or password.\n5 failed attempts will result in your account being locked.'];
            }
            return validate.single(username, { presence: { allowEmpty: false } });
          }}
          name="username"
          placeholder="Enter your username"
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            changeUsername(e.currentTarget.value)
          }
          value={username}
          properties={{ type: 'text', autoComplete: 'current-login' }}
        />
        <label htmlFor="password-input">Password</label>
        <InputPassword
          width="wide"
          formName={formName}
          name="password"
          placeholder="Enter your password"
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            changePassword(e.currentTarget.value)
          }
          validateFunc={() =>
            validate.single(password, { presence: { allowEmpty: false } })
          }
          value={password}
        />
      </div>

      <div className="actions">
        <div
          tabIndex={0}
          role='button'
          onClick={resetPassword}
          onKeyDown={resetPasswordKeyDown}
          className="btn-link"
        >
          {profileState.requestingPasswordReset ? (
            <Spinner small />
          ) : (
            'Reset password'
          )}
        </div>
        <Button
          width="normal"
          type="submit"
          className={'btn btn-success btn-login'}
          loadingInline={profileState.loggingin}
        >
          Login
        </Button>
      </div>
    </form>
  );
});
