import { action } from "mobx";
import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useContext } from "react";
import { actionsContext } from "../../../state/actionsState";
import { toastSubject } from "../../../state/rxjs";
import { TextField } from "../../common/input/TextField";
import { PopupEvent } from "../../common/popup/Popup";
import { ModalProps } from "../Modals";
import { SelectInfo } from "./selectinfo/SelectInfo";
import { ActionSubmit } from "./submit/ActionSubmit";
import validate from "validate.js";
import { validator } from "../../common/input/validator";

export const TakeOffHold: FC<ModalProps> = observer(
  ({ showModal, closeModal }) => {
    const AS = useContext(actionsContext);
    const formName = "TakeOffHold";

    const changeComment = action((e: React.FormEvent<HTMLTextAreaElement>) => {
      AS.offHoldComments = e.currentTarget.value;
    });

    const submit = useCallback(() => {
      if (AS.submitting) {
        return;
      }
      validator
        .validate(formName)
        .then(() => {
          AS.submitTakeOffHold()
            .then(res => {
              toastSubject.next(PopupEvent.TAKE_OFF_HOLD_SUCCESS);
              closeModal();
            })
            .catch(err => {
              toastSubject.next(err.message);
            });
        })
        .catch(() => 0);
    }, [AS, closeModal]);

    if (!showModal) {
      return null;
    }

    return (
      <>
        <div>
          <SelectInfo />
          <h3>Comments</h3>
          <TextField
            formName={formName}
            validateFunc={() =>
              validate.single(AS.offHoldComments, {
                presence: { allowEmpty: false }
              })
            }
            placeholder="Add a comment"
            name="comments"
            onChange={changeComment}
            value={AS.offHoldComments}
            className="wide"
          />
        </div>
        <ActionSubmit
          submitting={AS.submitting}
          cancel={closeModal}
          submit={submit}
          submitText="Take off hold"
        />
      </>
    );
  }
);
