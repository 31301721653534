import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';
import { __RouterContext } from 'react-router';
import { LOGIN, RESET_PASSWORD } from '../../routes';
import { appContext } from '../../state/appState';
import { profileContext } from '../../state/profileState';
import { useImages } from '../../utils/hooks';
import { AnimatedArea } from '../common/blocks/AnimatedArea';
import { Dropdown } from './Dropdown';
import './Header.css';
import { NavPanel } from './NavPanel';

export const Header = observer(() => {
  const routerState = useContext(__RouterContext);
  const profileState = useContext(profileContext);
  const appState = useContext(appContext);

  const [profileImgReady, setProfileImgReady] = useState(false);
  const [logoReady, setLogoReady] = useState(false);
  const profileImgUrl = `/api/download?f=${profileState.logoId}&s=${profileState.sessionId}`;

  const images = useImages([profileImgUrl]);

  if (
    routerState.location.pathname.indexOf(LOGIN) > -1 ||
    routerState.location.pathname.indexOf(RESET_PASSWORD) > -1 ||
    !profileState.authenticated
  ) {
    return null;
  }

  return (
    <header className='HeaderWrapper'>
      <div className='Header'>
        <nav>
          <a className='skip-to-content btn' href='#main'>Skip to content</a>
        </nav>
        <div className='img-logo-wrapper'>
          <img
            className={'img-logo' + (logoReady ? ' ready' : '')}
            src={'/logos/' + appState.theme + '.png'}
            onLoad={() => setLogoReady(true)}
            alt=''
          />
        </div>
        <Dropdown />
        <div className='img-profile-wrapper'>
          <AnimatedArea
            width
            style={{ height: '100%' }}
            contentStyle={{ height: '100%' }}
          >
            {images[profileImgUrl] ? (
              <img
                key={profileState.logoId || ''}
                className={'img-profile ready'}
                src={images[profileImgUrl]!.base64}
                alt='profile-img'
              />
            ) : null}
          </AnimatedArea>
        </div>
      </div>
      {routerState.location.pathname === '/' ? null : <NavPanel />}
    </header>
  );
});
