import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useContext, useEffect } from 'react';
import { actionsContext } from '../../../state/actionsState';
import { toastSubject } from '../../../state/rxjs';
import { InputSelect } from '../../common/input/InputSelect';
import { TextField } from '../../common/input/TextField';
import { validator } from '../../common/input/validator';
import { PopupEvent } from '../../common/popup/Popup';
import { Spinner } from '../../common/spinner/Spinner';
import { ModalProps } from '../Modals';
import { SelectInfo } from './selectinfo/SelectInfo';
import { ActionSubmit } from './submit/ActionSubmit';
import validate from 'validate.js';
import { useDots } from '../../../utils/hooks';

export const RequestReturn: FC<ModalProps> = observer(
  ({ showModal, closeModal }) => {
    const AS = useContext(actionsContext);

    const formName = 'RequestReturnModal';

    let caseId: string = '';
    if (AS.selectedDebtors.size > 0) {
      caseId = AS.selectedDebtors.keys().next().value.cases[0].id;
    } else if (AS.selectedCases.size > 0) {
      caseId = AS.selectedCases.keys().next().value.id;
    }

    const changeCategory = action((value: string) => {
      AS.returnCategory = value;
    });

    const changeComment = action((e: React.FormEvent<HTMLTextAreaElement>) => {
      AS.returnComments = e.currentTarget.value;
    });

    const submit = useCallback(() => {
      if (AS.submitting) {
        return;
      }

      validator
        .validate(formName, true)
        .then(() => {
          AS.submitRequestReturn()
            .then((res) => {
              toastSubject.next(PopupEvent.REQUEST_RETURN_SUCCESS);
              closeModal();
            })
            .catch((err) => {
              toastSubject.next(err.message);
            });
        })
        .catch(() => 0);
    }, [AS, closeModal, formName]);

    useEffect(() => {
      if (showModal) {
        AS.getReturnCategories(caseId);
      }
    }, [AS, showModal, caseId]);

    const loadingDots = useDots();

    if (!showModal) {
      return null;
    }

    return (
      <>
        {' '}
        <div>
          <SelectInfo />
          <h3>Return details</h3>
          <label style={{ marginBottom: '5px', display: 'block' }}>
            Return category
          </label>
          {AS.returnCategories ? (
            <InputSelect
              width="grow"
              formName={formName}
              options={AS.returnCategories}
              placeholder="Select category"
              name="return-category"
              selectedThing={AS.returnCategory}
              onChange={changeCategory}
              validateFunc={() =>
                validate.single(AS.returnCategory, {
                  presence: {
                    allowEmpty: false,
                    message: 'Category is required',
                  },
                })
              }
            />
          ) : (
            <InputSelect
              width="grow"
              formName={formName}
              options={{}}
              placeholder={'Loading' + loadingDots}
              name="return-category"
              selectedThing={AS.returnCategory}
              onChange={changeCategory}
              validateFunc={() =>
                validate.single(AS.returnCategory, {
                  presence: {
                    allowEmpty: false,
                    message: 'Category is required',
                  },
                })
              }
            />
          )}

          <h3>Comments</h3>
          <TextField
            formName={formName}
            placeholder="Add a comment"
            name="comments"
            onChange={changeComment}
            value={AS.returnComments}
            className="wide"
            validateFunc={() =>
              validate.single(AS.returnComments, {
                presence: { allowEmpty: false },
              })
            }
          />
        </div>
        <ActionSubmit
          submitting={AS.submitting}
          cancel={closeModal}
          submit={submit}
          submitText="Return cases"
        />
      </>
    );
  }
);
