import $ from 'jquery';
import React, {
  ComponentType,
  FC,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { offsetHtmlScrollbar } from '../../../utils/getScrollbarWidth';
import { useResize, useScroll } from '../../../utils/hooks';
import { isMobile } from '../../../utils/isMobile';
import { BlockMobile } from '../blocks/Blocks';
import { Button } from '../button/Button';
import './DropdownUniversal.css';

export interface DropdownContentProps {
  close: () => void;
  isOpened: boolean;
  props?: any;
}

export interface DropdownTogglerProps {
  close: () => void;
  isOpened: boolean;
  props?: any;
}
interface DropdownProps {
  className?: string;
  classNameContent?: string;
  content: FC<DropdownContentProps> | ComponentType<DropdownContentProps>;
  toggler: FC<DropdownTogglerProps> | ComponentType<DropdownTogglerProps>;
  afterToggler?: JSX.Element;
  mobileBottomContentBlock?: JSX.Element;
  props?: any;
  fixed?: boolean;
  fixedContext?: boolean;
  onShow?: () => void;
  onClose?: () => void;
  onBlur?: () => void;
  togglerProps?: any;
  closeOnContentBlur?: boolean;
  focusTogglerOnClose?: boolean;
}

// see InputSelect.tsx for usage example
export const DropdownUniversal = ({
  className,
  classNameContent,
  content,
  toggler,
  afterToggler,
  mobileBottomContentBlock,
  props,
  fixed,
  fixedContext,
  onShow,
  onClose,
  onBlur,
  togglerProps,
  closeOnContentBlur = true,
  focusTogglerOnClose = true,
}: DropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const togglerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  // 'focusing' is used to separate click and focus events - dropdown should close on
  // toggler click, but not on the click that happens right after focus/pointerdown
  const focusingClosed = useRef(false);
  const focusingOpened = useRef(false);
  const [isOpened, setIsOpened] = useState(false);
  const [bottomAligned, setBottomAligned] =
    useState<boolean | undefined>(undefined);
  const [rightAligned, setRightAligned] =
    useState<boolean | undefined>(undefined);

  const MemoizedContent = useMemo(() => {
    return content;
  }, [content]);

  const MemoizedToggler = useMemo(() => {
    return toggler;
  }, [toggler]);

  const handlePointerDown = (e: React.MouseEvent) => {
    if (!isOpened) {
      focusingClosed.current = true;
      open();
    } else {
      focusingOpened.current = true;
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!dropdownRef.current?.contains(e.relatedTarget as any) &&
      !contentRef.current?.contains(e.relatedTarget as any)) {
      close();
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    switch (e.code) {
      case 'Space':
      case 'Enter': {
        e.preventDefault();
        toggleContent();
      }
    }
  }

  const toggleContent = () => {
    if (focusingClosed.current) {
      return;
    }
    focusingClosed.current = false;
    focusingOpened.current = false;
    if (!isOpened) {
      open();
    } else {
      close();
    }
  };

  const handleDocumentClick = useCallback((e: MouseEvent) => {
    if (focusingClosed.current || focusingOpened.current) {
      focusingClosed.current = false;
      focusingOpened.current = false;
      return;
    }
    if (
      dropdownRef.current?.contains(e.target as any) ||
      contentRef.current?.contains(e.target as any)
    ) {
      return;
    }
    close();
  }, []);

  const handleDocumentPointerDown = useCallback((e: MouseEvent) => {
    if (focusingClosed.current || focusingOpened.current) {
      return;
    }
    if (
      dropdownRef.current?.contains(e.target as any) ||
      contentRef.current?.contains(e.target as any)
    ) {
      return;
    }
    close();
  }, []);

  const handleContentClick = (e: React.MouseEvent) => {
    if (isMobile() && isOpened) {
      close();
    }
  };

  const handleContentBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (closeOnContentBlur && !contentRef.current?.contains(e.relatedTarget as any)) {
      closeFromContent();
    }
  }

  const closeFromContent = () => {
    if (focusTogglerOnClose) {
      togglerRef.current?.focus();
    }
    close();
  };

  const close = () => {
    document.removeEventListener('click', handleDocumentClick);
    document.removeEventListener('mousedown', handleDocumentPointerDown);
    setIsOpened(false);
    if (onClose) {
      onClose();
    }
    if (isMobile()) {
      offsetHtmlScrollbar(false);
    }
  };

  const open = () => {
    setBottomAligned(undefined);
    setRightAligned(undefined);
    setIsOpened(true);
    if (onShow) {
      onShow();
    }
    document.addEventListener('click', handleDocumentClick);
    document.addEventListener('mousedown', handleDocumentPointerDown);
    if (isMobile()) {
      offsetHtmlScrollbar(true);
    }
  };

  const getAlignment = () => {
    if (!contentRef.current) {
      return;
    }
    const containerElement = contentRef.current;
    const offsets = containerElement.getBoundingClientRect();
    let targetBottom = offsets.top + containerElement.clientHeight;
    let targetRight = offsets.left + containerElement.clientWidth;
    if (
      targetRight > window.innerWidth - 10 &&
      offsets.left - containerElement.clientWidth > 0
    ) {
      setRightAligned(true);
    } else {
      setRightAligned(false);
    }
    if (
      targetBottom > window.innerHeight - 10 &&
      offsets.top - containerElement.clientHeight > 0
    ) {
      setBottomAligned(true);
    } else {
      setBottomAligned(false);
    }
  };

  const prepareFixed = () => {
    // adjust top and left to prevent container going outside of the screen
    const containerEl = contentRef.current;
    const togglerEl = togglerRef.current;
    if (!containerEl || !togglerEl) {
      return;
    }
    containerEl.style.width = togglerEl.clientWidth + 'px';
    let offsets;
    offsets = dropdownRef.current!.getBoundingClientRect();

    let targetTop = offsets.top + togglerEl.clientHeight - 5;
    let targetLeft = offsets.left;
    // below bottom
    if (targetTop + containerEl.scrollHeight > window.innerHeight - 20) {
      const newTargetTop = offsets.top - containerEl.scrollHeight;
      if (newTargetTop < 0) {
        setBottomAligned(false);
      } else {
        targetTop = newTargetTop;
        setBottomAligned(true);
      }
    } else {
      setBottomAligned(false);
    }

    if (targetLeft + containerEl.clientWidth > window.innerWidth) {
      setRightAligned(true);
      targetLeft =
        offsets.left - containerEl.clientWidth + togglerEl.clientWidth;
    } else {
      setRightAligned(false);
    }

    containerEl.style.top = targetTop + 'px';
    containerEl.style.left = targetLeft + 'px';
  };

  const prepareFixedContext = () => {
    // adjust top and left to prevent container going outside of the screen
    const containerEl = contentRef.current;
    const togglerEl = togglerRef.current;

    if (!containerEl || !togglerEl) {
      return;
    }
    let offsets;
    offsets = dropdownRef.current!.getBoundingClientRect();

    let targetTop = offsets.top;
    let targetLeft = offsets.left + togglerEl.clientWidth + 11;
    // below bottom
    if (targetTop + containerEl.clientHeight > window.innerHeight - 20) {
      targetTop = window.innerHeight - containerEl.clientHeight - 20;
      setBottomAligned(true);
    } else {
      setBottomAligned(false);
    }

    // above top
    if (targetTop < 10) {
      targetTop = 10;
    }

    setRightAligned(false);
    containerEl.style.top = targetTop + 'px';
    containerEl.style.left = targetLeft + 'px';
  };

  const closeFixed = () => {
    if ((fixed || fixedContext) && isOpened && window.innerWidth > 768) {
      close();
    }
  };

  const recalcPositions = () => {
    if (fixed) {
      prepareFixed();
    } else if (fixedContext) {
      prepareFixedContext();
    } else {
      getAlignment();
    }
  };

  useResize(recalcPositions);
  useScroll(closeFixed, { ignoreResize: true });

  return (
    <div
      className={
        'DropdownUniversal ' +
        (fixed ? 'fixed ' : '') +
        (className ? className : '')
      }
      ref={dropdownRef}
    >
      <Portalizer enabled={fixedContext}>
        <CSSTransition
          onEntering={() => {
            if (fixed) {
              prepareFixed();
            } else if (fixedContext) {
              prepareFixedContext();
            } else {
              getAlignment();
            }
          }}
          in={isOpened}
          unmountOnExit
          addEndListener={(node, done) =>
            node.addEventListener('transitionend', done, false)
          }
          classNames='dropdown-fade'
        >
          <div
            onClick={handleContentClick}
            className={
              'dropdown__content dropdown-fade ' +
              (bottomAligned === true
                ? ' align-bottom'
                : bottomAligned === false
                ? ''
                : ' wait-alignment') +
              (rightAligned === true
                ? ' align-right'
                : rightAligned === false
                ? ''
                : ' wait-alignment') +
              (fixedContext || fixed ? ' dropdown__content--fixed' : '') +
              (classNameContent ? ' ' + classNameContent : '')
            }
            ref={contentRef}
            onBlur={handleContentBlur}
          >
            <div
              className='scroller'
              tabIndex={-1}
              onClick={(e) => (isMobile() ? e.stopPropagation() : null)}
            >
              <MemoizedContent
                isOpened={isOpened}
                close={closeFromContent}
                {...props}
              />
            </div>
            <BlockMobile>
              <div className='mobile-dark-overlay'></div>
              <div className='mobile-bottom-block'>
                {mobileBottomContentBlock ? { mobileBottomContentBlock } : null}
                <Button className='btn-secondary' onClick={close}>
                  Close
                </Button>
              </div>
            </BlockMobile>
          </div>
        </CSSTransition>
      </Portalizer>
      <div
        ref={togglerRef}
        className={'Toggler ' + (isOpened ? 'active' : '')}
        tabIndex={0}
        onClick={toggleContent}
        onMouseDown={handlePointerDown}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        {...togglerProps}
      >
        <MemoizedToggler isOpened={isOpened} close={close} {...props} />
      </div>
      {afterToggler}
    </div>
  );
};

export const Portalizer = ({ enabled, children }) => {
  if (enabled) {
    return ReactDOM.createPortal(children, document.body);
  }
  return children;
};
