import React, { useRef } from 'react';
import { FILE_UPLOAD_MAX_SIZE } from '../../../config';
import { toastSubject } from '../../../state/rxjs';
import { Button } from '../button/Button';
import { IconCross } from '../icons';
import './InputFile.css';
import { ValidationError } from './ValidationError';
require('cleave.js/dist/addons/cleave-phone.gb');

interface InputProps {
  formName?: string;
  onChange?: (file: File | null) => void;
  validateFunc?: () => any;
  value?: File | null;
  name: string;
  label?: JSX.Element | string;
  classNames?: string;
  readOnly?: true;
  placeholder?: string;
}

export const InputFile: React.FC<InputProps> = ({
  formName,
  validateFunc,
  onChange,
  name,
  value,
  label,
  classNames,
  readOnly,
  placeholder,
}) => {
  const fileInputRef = useRef(null);

  const getFile = (files: FileList) => {
    /* const fileType = /image\/png|image\/jpeg|image\/tiff|image\/gif|image\/bmp|application\/pdf|application\/msword/; */
    const file = files[0];
    // if (!file.type.match(fileType)) {
    //  continue;
    // }
    if (file.size > FILE_UPLOAD_MAX_SIZE) {
      toastSubject.next('This file is over 20MB.');
      return;
    }
    return file;
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const files = (fileInputRef.current! as HTMLInputElement).files;
    if (!files) {
      return;
    }
    const file = getFile(files);
    if (file && onChange) {
      onChange(file);
    }
  };

  const handleFilePickClick = () => {
    const filePicker = fileInputRef.current! as HTMLInputElement;
    filePicker.click();
  };

  const clearFile = () => {
    if (onChange) {
      onChange(null);
    }
    const filePicker = fileInputRef.current! as HTMLInputElement;
    filePicker.value = '';
  };

  return (
    <div id={name} className={'InputFile ' + (classNames ? classNames : '')}>
      {label ? <label className="input-label" htmlFor="filePicker">{label}</label> : null}
      <div className="input-wrapper">
        {!formName ? null : (
          <ValidationError
            value={value}
            inputId={name}
            formId={formName}
            validateFunc={validateFunc}
            dirty={value ? true : undefined}
          />
        )}
        <div className={'input-container'}>
          <input
            ref={fileInputRef}
            type="file"
            id="filePicker"
            className="filePicker"
            name="filePicker"
            // accept={acceptedFileTypes}
            onChange={handleChange}
            placeholder={'Choose file'}
            aria-label={'Choose file'}
          />
          <span className={'input-filename' + (value ? '' : ' empty')}>
            {value ? value.name : 'No file selected'}
          </span>
          {value ? (
            <button className="btn-clearfile" onClick={clearFile} aria-label='Clear file'>
              <IconCross />
            </button>
          ) : null}
          <Button onClick={handleFilePickClick} className="btn btn-filepick">
            Select file
          </Button>
        </div>
      </div>
    </div>
  );
};
