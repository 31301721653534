import { action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { FC, useCallback, useContext } from 'react';
import validate from 'validate.js';
import { DebtorDto } from '../../../server-api/model';
import { actionsContext } from '../../../state/actionsState';
import { debtorContext } from '../../../state/debtorState';
import { toastSubject } from '../../../state/rxjs';
import { useValidation } from '../../../utils/hooks';
import { InputFile } from '../../common/input/InputFile';
import { InputSelect } from '../../common/input/InputSelect';
import { TextField } from '../../common/input/TextField';
import { validator } from '../../common/input/validator';
import { PopupEvent } from '../../common/popup/Popup';
import { Spinner } from '../../common/spinner/Spinner';
import { ModalProps } from '../Modals';
import { SelectInfo } from './selectinfo/SelectInfo';
import { ActionSubmit } from './submit/ActionSubmit';
import './UploadFile.css';

export const UploadFile: FC<ModalProps> = observer(
  ({ showModal, closeModal }) => {
    const AS = useContext(actionsContext);
    const debtorState = useContext(debtorContext);

    const formName = 'UploadFile';

    let debtor: DebtorDto | null = null;
    if (AS.selectedDebtors.size > 0) {
      debtor = AS.selectedDebtors.keys().next().value;
    } else if (AS.selectedCases.size > 0) {
      debtor = AS.selectedCases.keys().next().value.debtor;
    } else if (debtorState.loadedDebtor) {
      debtor = debtorState.loadedDebtor;
    }

    const changeCategory = action((value: string) => {
      AS.fileCategory = value;
    });

    const changeComment = action((e: React.FormEvent<HTMLTextAreaElement>) => {
      AS.fileComments = e.currentTarget.value;
    });

    const submit = useCallback(() => {
      if (AS.uploading) {
        return;
      }
      validator
        .validate(formName, true)
        .then(() => {
          if (!debtor) {
            toastSubject.next("No debtor, this shouldn't happen");
            return;
          }
          AS.submitFile(debtor.id)
            .then(() => {
              closeModal();
            })
            .catch((err) => {
              toastSubject.next(err.message);
            });
        })
        .catch(() => 0);
    }, [AS, formName, closeModal, debtor]);

    useValidation(formName);

    if (!showModal) {
      return null;
    }

    return (
      <>
        {AS.fileCategories ? null : <Spinner blocking={true} />}
        <div style={{ position: 'relative' }}>
          <SelectInfo />
          <h3>Select File</h3>
          <InputFile
            validateFunc={() =>
              validate.single(AS.droppedFile, {
                presence: { allowEmpty: false },
              })
            }
            value={AS.droppedFile}
            label='File'
            formName={formName}
            name='file-file'
            onChange={(file) => {
              runInAction(() => {
                AS.droppedFile = file;
              });
            }}
          />

          <InputSelect
            formName={formName}
            options={AS.fileCategories}
            label='File category'
            placeholder='Select category'
            name='file-category'
            selectedThing={AS.fileCategory}
            onChange={changeCategory}
            validateFunc={() =>
              validate.single(AS.fileCategory, {
                presence: {
                  allowEmpty: false,
                  message: 'Category is required',
                },
              })
            }
          />
          <h3>Comments</h3>
          <TextField
            placeholder='Add a comment'
            name='comments'
            onChange={changeComment}
            value={AS.fileComments}
            className='wide'
          />
          {AS.uploading ? <Spinner blocking noSpinner /> : null}
        </div>
        <ActionSubmit
          cancel={closeModal}
          submit={submit}
          submitting={AS.uploading}
          submitText={'Upload file'}
        />
      </>
    );
  }
);
