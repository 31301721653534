import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DATE_FORMAT_SHORTER } from '../../../config';
import { DEBTOR } from '../../../routes';
import { RecentDebtor } from '../../../server-api/model';
import { GenericSorter } from '../../../state/genericSorter';
import { miscContext } from '../../../state/miscState';
import { CustomScrollbars } from '../../common/scrollbars/Scrollbars';
import { SortHeaderCell } from '../../common/table/SortHeaderCell';
import { LIST_TAB } from './Lists';

interface RecentsListProps {
  selectedTab: string;
}

export const RecentsList = observer((props: RecentsListProps) => {
  const miscState = useContext(miscContext);

  const [sorter, setSorter] = useState(
    new GenericSorter(Array.from(miscState.recents.values()), 'date', false)
  );

  useEffect(() => {
    const disposer = reaction(
      () => {
        return miscState.recents.keys();
      },
      () => {
        const newSorter = new GenericSorter(
          Array.from(miscState.recents.values()),
          'date',
          false
        );
        setSorter(newSorter);
      }
    );
    return disposer;
  }, [miscState]);

  if (props.selectedTab !== LIST_TAB.RECENTS) {
    return null;
  }

  return (
    <div role="table" aria-label="Recently Viewed" className="list-view recent-list">
      <div role="rowgroup">
        <div role="row" className="thead">
          <SortHeaderCell
            className="list-date"
            sorter={sorter}
            type="date"
            text="Viewed"
          />
          <SortHeaderCell
            className="list-debtor"
            sorter={sorter}
            type="name"
            text="Customer"
          />
        </div>
      </div>
      <div className="scrollbar-fit-container" style={{ flexGrow: 1 }}>
        <CustomScrollbars
          style={{ height: '100%' }}
          scrollbarStyle={{ right: '-16px' }}
        >
          <div role="rowgroup" className="tbody">
            {sorter.sortedEntries.map((debtor: RecentDebtor, index) => {
              return (
                <div role="row" className="tr entry" key={'entry' + index}>
                  <div role="cell" className="td list-date">
                    {moment(debtor.date).format(DATE_FORMAT_SHORTER)}
                  </div>
                  <div role="cell" className="td list-debtor">
                    <Link to={DEBTOR + '/' + debtor.id}>
                      {debtor.name}
                      {debtor.prefix ? (
                        <span className="prefix">{debtor.prefix}</span>
                      ) : null}
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </CustomScrollbars>
      </div>
    </div>
  );
});
