import { CleaveOptions } from 'cleave.js/options';
import Cleave from 'cleave.js/react';
import React, { ReactNode, RefObject, useState } from 'react';
import MaskedInput from 'react-text-mask';
import { DEFAULT_MAX_LENGTH } from '../../../config';
import { CommonInputProps, InputWidth } from './common';
import './Input.css';
import './UITheme.css';
import { ValidationError } from './ValidationError';
require('cleave.js/dist/addons/cleave-phone.gb');

export interface InputProps extends CommonInputProps {
  value?: string | number | null;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  afterInputComponent?: JSX.Element | ReactNode;
  onFocusIn?: () => any;
  onFocusOut?: () => any;
  options?: CleaveOptions;
  defaultValue?: string;
  size?: number;
  maxLength?: number;
  type?: string;
  readOnly?: true;
  autocomplete?: string;
  mask?: boolean | (string | RegExp)[];
  properties?: any;
  lengthCounter?: boolean;
  action?: () => any;
  actionLabel?: string;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => any;
  htmlRef?: RefObject<HTMLInputElement>;
}

export const Input: React.FC<InputProps> = ({
  width = 'normal',
  value,
  defaultValue,
  formName,
  validateFunc,
  onChange,
  onFocusIn,
  onFocusOut,
  maxLength,
  size,
  afterInputComponent,
  name,
  label,
  type,
  options,
  className,
  readOnly,
  autocomplete,
  placeholder,
  mask,
  properties,
  lengthCounter,
  onPaste,
  htmlRef,
}) => {
  const [focused, setFocused] = useState(false);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (onChange) {
      if (options) {
        // swapping rawValue and formatted value
        // to access rawValue inside standard e.currentTarget.value
        e.currentTarget = e.target as any;
        (e.currentTarget as any).formattedValue = (e.target as any).value;
        e.currentTarget.value = (e.target as any).rawValue;
        onChange(e);
      } else {
        onChange(e);
      }
    }
  };

  const sharedProps = {
    defaultValue: defaultValue,
    maxLength: maxLength || DEFAULT_MAX_LENGTH,
    name: name + '-input',
    id: name + '-input',
    size: size,
    onChange: handleChange,
    onFocus: () => {
      setTimeout(() => {
        setFocused(true);
        if (onFocusIn) onFocusIn();
      }, 0);
    },
    onBlur: () => {
      setFocused(false);
      if (onFocusOut) onFocusOut();
    },
    onPaste: onPaste,
    value: value !== null ? value : undefined,
    readOnly: readOnly,
    autoComplete: autocomplete,
    placeholder: placeholder,
    type: type === 'number' ? 'tel' : type,
    ...properties,
  };

  const getClassnames = () => {
    let cls = 'input-' + width;
    cls += className ? ' ' + className : '';
    return cls;
  };

  return (
    <div id={name} className={'Input ' + getClassnames()}>
      {label ? <label className="input-label" htmlFor={sharedProps.id}>{label}</label> : null}
      <div className="input-wrapper">
        <div className={'input-container' + (focused ? ' active' : '')}>
          {mask ? (
            <MaskedInput itemRef={htmlRef} {...sharedProps} mask={mask} />
          ) : options ? (
            <Cleave
              {...sharedProps}
              options={options || { blocks: [99999], delimiter: '' }}
              htmlRef={htmlRef}
            />
          ) : (
            <input {...sharedProps} ref={htmlRef} />
          )}
          {afterInputComponent ? afterInputComponent : null}
        </div>
        {!formName ? null : (
          <ValidationError
            value={value}
            inputId={name}
            formId={formName}
            validateFunc={validateFunc}
            active={focused}
            dirty={value ? true : undefined}
          />
        )}
      </div>
      {lengthCounter ? (
        <div className="char-limit-counter">
          {value ? value.toString().length : 0}/{maxLength}
        </div>
      ) : null}
    </div>
  );
};
