import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { debounceTime } from 'rxjs/operators';
import {
  DATE_FORMAT_SHORTER,
  DEBOUNCE_AFTER_ACTION_DURATION,
} from '../../../config';
import { Case, CaseDetails } from '../../../server-api/model';
import { caseContext } from '../../../state/caseState';
import { debtorContext } from '../../../state/debtorState';
import { actionSuccessEvent } from '../../../state/rxjs';
import { Spinner } from '../../common/spinner/Spinner';
import { CasePayment } from './payments/CasePayments';
import { SectionArrest } from './sections/SectionArrest';
import { SectionDebtRecovery } from './sections/SectionDebtRecovery';
import { SectionEmpty } from './sections/SectionEmpty';
import { SectionHMCTS } from './sections/SectionHMCTS';
import { SectionLO } from './sections/SectionLO';
import { SectionWarrant } from './sections/SectionWarrant';
import { CaseStatement } from './statement/CaseStatement';

interface CaseExpansionProps {
  caze: Case;
}

export const CaseExpansion: FC<CaseExpansionProps> = observer(({ caze }) => {
  const caseState = useContext(caseContext);
  const expRef = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState(false);
  const [details, setDetails] = useState<null | CaseDetails>(null);
  const { debtorDetails } = useContext(debtorContext);

  const getCaseDetails = () => {
    caseState.getCaseDetails(caze).then((res: CaseDetails) => {
      setDetails(res);
    });
  };

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      getCaseDetails();
    }
    const subscription = actionSuccessEvent
      .pipe(debounceTime(DEBOUNCE_AFTER_ACTION_DURATION))
      .subscribe(() => {
        getCaseDetails();
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [caze, loaded]);

  const getHoldStatus = (status?: string) => {
    if (!status) {
      return 'Not on hold';
    }
    switch (status.toLowerCase()) {
      case 'client':
        return 'On hold - Client';
      case 'internal':
        return 'On hold - ' + window.COMPANY_LABEL;
      default:
        return 'Not on hold';
    }
  };

  const getAgentType = (type?: string) => {
    switch (type) {
      case 'ANPR':
        return 'ANPR';
      case 'AO':
        return 'Arrest Officer';
      case 'EA':
        return 'Enforcement Agent';
      case 'E1':
        return 'Enforcement Agent';
      case 'E2':
        return 'Enforcement Agent';
      case 'E1/E2':
        return 'Enforcement Agent';
      default:
        return '';
    }
  };

  const getOtherRefs = (caze: Case) => {
    const jsxArr: JSX.Element[] = [];
    if (caze.altClientReference1) {
      jsxArr.push(<span>{caze.altClientReference1}</span>);
    }
    if (caze.altClientReference2) {
      if (jsxArr.length) {
        jsxArr.push(<br />);
      }
      jsxArr.push(<span>{caze.altClientReference2}</span>);
    }
    if (caze.altClientReference3) {
      if (jsxArr.length) {
        jsxArr.push(<br />);
      }
      jsxArr.push(<span>{caze.altClientReference3}</span>);
    }
    return jsxArr;
  };

  return (
    <div ref={expRef} className='CaseExpansion'>
      {!details ? (
        <div className='details-spinner'>
          <Spinner centered={true} />
        </div>
      ) : (
        <>
          <div className='flex-group row1'>
            <div className='case-details'>
              <h2 id='caseDetailsLabel'>Case details</h2>
              <table aria-labelledby='caseDetailsLabel'>
                <tbody>
                  <tr>
                    <th>Referred to {window.COMPANY_LABEL}</th>
                    <td>
                      {details.referredTo
                        ? moment(details.referredTo).format(DATE_FORMAT_SHORTER)
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <th>Contract</th>
                    <td>{details.contract}</td>
                  </tr>
                  <tr>
                    <th>Batch</th>
                    <td>{details.batchNumber}</td>
                  </tr>
                  <tr>
                    <th>{window.COMPANY_LABEL} case ID</th>
                    <td>{details.caseId}</td>
                  </tr>
                  <tr>
                    <th>Other refs</th>
                    <td className='multiline-container'>
                      {getOtherRefs(caze)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='case-status'>
              <h2 id='caseStatusLabel'>Case status</h2>
              <table aria-labelledby='caseStatusLabel'>
                <tbody>
                  <tr>
                    <th>Case status</th>
                    <td>{details.caseStatus}</td>
                  </tr>
                  <tr>
                    <th>Last action</th>
                    <td>
                      {details.lastAction
                        ? moment(details.lastAction).format(DATE_FORMAT_SHORTER)
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <th>Type</th>
                    <td>{details.type}</td>
                  </tr>
                  <tr>
                    <th>Hold status</th>
                    <td>{getHoldStatus(details.holdStatus)}</td>
                  </tr>
                  <tr>
                    <th>Hold expires</th>
                    <td>
                      {details.holdExpires
                        ? moment(details.holdExpires).format(
                            DATE_FORMAT_SHORTER
                          )
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <th>Hold reason</th>
                    <td>{details.holdReason}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {details.hmctsSection ? (
              <SectionHMCTS
                details={details.hmctsSection}
                showAdditionalParty={!!debtorDetails?.additionalParties.length}
              />
            ) : details.laRevenuesSection ? (
              <SectionLO details={details.laRevenuesSection} />
            ) : details.roadTrafficSection ? (
              <SectionWarrant details={details.roadTrafficSection} />
            ) : details.debtRecoverySection ? (
              <SectionDebtRecovery details={details.debtRecoverySection} />
            ) : details.laArrestSection ? (
              <SectionArrest details={details.laArrestSection} />
            ) : (
              <SectionEmpty />
            )}
          </div>
          <div className='flex-group row2'>
            <div>
              <h2 id='enforcementAgentLabel'>Enforcement Agent</h2>
              <div className='flex-group'>
                <div>
                  <table aria-labelledby='enforcementAgentLabel'>
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <td>
                          {details.agentSection && details.agentSection.name}
                        </td>
                      </tr>
                      <tr>
                        <th>Contact</th>
                        <td>
                          {details.agentSection && details.agentSection.contact}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table aria-labelledby='enforcementAgentLabel'>
                    <tbody>
                      <tr>
                        <th>Type</th>
                        <td>{getAgentType(details.agentSection?.type)}</td>
                      </tr>
                      <tr>
                        <th>Allocated</th>
                        <td>
                          {details.agentSection &&
                            moment(details.agentSection.allocated).format(
                              DATE_FORMAT_SHORTER
                            )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className='return-details'>
              <h2 id='returnDetailsLabel'>Return details</h2>
              <div className='flex-group'>
                <div className='ie-needs-div-child-for-flex-to-work-properly'>
                  <table aria-labelledby='returnDetailsLabel'>
                    <tbody>
                      <tr>
                        <th>Return code</th>
                        <td>{details.returnCode}</td>
                      </tr>
                      <tr>
                        <th>Description</th>
                        <td>{details.returnDescription}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table aria-labelledby='returnDetailsLabel'>
                    <tbody>
                      <tr>
                        <th>Return date</th>
                        <td>
                          {details.returnDate &&
                            moment(details.returnDate).format(
                              DATE_FORMAT_SHORTER
                            )}
                        </td>
                      </tr>
                      <tr>
                        <th>Remittance</th>
                        <td>{details.remittanceBatchId}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {details.hasPayments ? (
            <div className='flex-group3'>
              <div className='statement'>
                <CaseStatement caze={caze} />
              </div>
              <div className='payments'>
                <CasePayment caze={caze} />
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
});
