import { action } from "mobx";
import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useContext } from "react";
import { DebtorDto } from "../../../server-api/model";
import { actionsContext } from "../../../state/actionsState";
import { debtorContext } from "../../../state/debtorState";
import { toastSubject } from "../../../state/rxjs";
import { useKeys } from "../../../utils/hooks";
import { InputSelect } from "../../common/input/InputSelect";
import { TextField } from "../../common/input/TextField";
import { validator } from "../../common/input/validator";
import { PopupEvent } from "../../common/popup/Popup";
import { Spinner } from "../../common/spinner/Spinner";
import { ModalProps } from "../Modals";
import { SelectInfo } from "./selectinfo/SelectInfo";
import { ActionSubmit } from "./submit/ActionSubmit";
import validate from "validate.js";

export const AddNote: FC<ModalProps> = observer(({ showModal, closeModal }) => {
  const AS = useContext(actionsContext);
  const debtorState = useContext(debtorContext);
  const formName = "AddNote";

  let debtor: DebtorDto | undefined;
  if (AS.selectedCases.size > 0) {
    debtor = undefined;
  } else if (AS.selectedDebtors.size > 0) {
    debtor = AS.selectedDebtors.keys().next().value;
  } else if (debtorState.loadedDebtor) {
    debtor = debtorState.loadedDebtor;
  }

  const changeType = action((value: string) => {
    AS.noteType = value;
  });

  const changeNote = action((e: React.FormEvent<HTMLTextAreaElement>) => {
    AS.noteComments = e.currentTarget.value;
  });

  const submit = useCallback(() => {
    if (AS.submitting) {
      return;
    }

    validator
      .validate(formName, true)
      .then(() => {
        AS.submitNote(debtor && debtor.id)
          .then(() => {
            toastSubject.next(PopupEvent.ADD_NOTE_SUCCESS);
            closeModal();
          })
          .catch(err => {
            toastSubject.next(err.message);
          });
      })
      .catch(() => 0);
  }, [AS, formName, closeModal, debtor]);

  useKeys(["Enter"], [submit], !showModal);

  if (!showModal) {
    return null;
  }

  return (
    <>
      {AS.noteCategories ? null : <Spinner blocking={true} />}
      <div>
        <SelectInfo debtor={debtor} />
        <h3>Note details</h3>

        <InputSelect
          placeholder={"Select type"}
          formName={formName}
          options={AS.noteCategories}
          label="Note type"
          name="note-type"
          selectedThing={AS.noteType}
          onChange={changeType}
          validateFunc={() =>
            validate.single(AS.noteType, {
              presence: { allowEmpty: false, message: "Type is required" }
            })
          }
        />
        <h3>Note</h3>
        <TextField
          formName={formName}
          placeholder="Add a note"
          name="note"
          onChange={changeNote}
          value={AS.noteComments}
          className="wide"
          validateFunc={() =>
            validate.single(AS.noteComments, {
              presence: { allowEmpty: false }
            })
          }
        />
      </div>
      <ActionSubmit
        submitting={AS.submitting}
        cancel={closeModal}
        submit={submit}
        submitText="Add note"
      />
    </>
  );
});
