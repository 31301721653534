import { observer } from 'mobx-react';
import React, { useContext, useEffect, useCallback, useState, FC } from 'react';
import { __RouterContext } from 'react-router';
import { modalContext } from '../../state/modalState';
import { profileContext } from '../../state/profileState';
import { searchContext } from '../../state/search/searchState';
import { BlockMobile } from '../common/blocks/Blocks';
import {
  DropdownContentProps,
  DropdownTogglerProps,
  DropdownUniversal,
} from '../common/dropdown/DropdownUniversal';
import { IconSortDown, IconMenu } from '../common/icons';
import { Spinner } from '../common/spinner/Spinner';
import './Dropdown.css';
import { DDListContainer, DDListOption } from 'components/common/input/common';

export const Dropdown = observer(() => {
  const routerState = useContext(__RouterContext);
  const profileState = useContext(profileContext);
  const modalState = useContext(modalContext);
  const searchState = useContext(searchContext);
  const [active, setActive] = useState(false);

  const doLogout = () => {
    routerState.history.push('/login');
    profileState.logout();
    searchState.clearSearch();
  };

  const openProfileModal = () => {
    modalState.toggleModal('profile', true);
  };

  const openChangePasswordModal = () => {
    modalState.toggleModal('changePassword', true);
  }

  if (!profileState.profileData || profileState.updatingProfile) {
    return <Spinner dots={true} style={{ top: '5px', right: '20px' }} />;
  }
  return (
    <>
      <DropdownUniversal
        className={'user-menu'}
        onShow={() => { setActive(true); }}
        onClose={() => { setActive(false); }}
        toggler={Toggler}
        focusTogglerOnClose={false}
        content={({ close }: DropdownContentProps) => (
          <div className='user-menu__content'>
            <BlockMobile>
              <span
                onClick={close}
                className='user-menu__label user-menu__label--mobile'
              >
                {!profileState.profileData!.firstName &&
                !profileState.profileData!.lastName ? (
                  'Profile'
                ) : (
                  <>
                    {profileState.profileData!.firstName}{' '}
                    {profileState.profileData!.lastName}
                  </>
                )}
              </span>
            </BlockMobile>
            <DDListContainer
              id='user-menu'
              active={active}
              kbdSelector={'div.select-option'}
              role='menu'
              aria-labelledby='user-menu-label'
            >
              <DDListOption index={0} onClick={openProfileModal} role='menuitem'>
                My details
              </DDListOption>
              <DDListOption index={1} onClick={openChangePasswordModal} role='menuitem'>
                Change password
              </DDListOption>
              <DDListOption index={2} onClick={doLogout} role='menuitem'>
                Logout
              </DDListOption>
            </DDListContainer>
          </div>
        )}
        togglerProps={{
          'role': 'button',
          'aria-labelledby': 'user-menu-label',
          'aria-haspopup': 'true',
          'aria-expanded': active,
          'aria-controls': active ? 'user-menu' : undefined
        }}
      />
    </>
  );
});

const Toggler: FC<DropdownTogglerProps> = ({ isOpened }) => {
  const profileState = useContext(profileContext);

  return (
    <div className='user-menu__toggler'>
    <span id='user-menu-label' className='user-menu__label'>
      {!profileState.profileData!.firstName &&
      !profileState.profileData!.lastName ? (
        'Profile'
      ) : (
        <>
          {profileState.profileData!.firstName}{' '}
          {profileState.profileData!.lastName}
        </>
      )}
    </span>
    <div
      className={
        'user-menu__toggler-icon ' + (isOpened ? 'flipped' : '')
      }
    >
      <IconSortDown />
      <IconMenu />
    </div>
  </div>
  );
};