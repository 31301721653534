import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { modalCloseSubject } from '../../../state/rxjs';
import { ModalProps } from '../../modals/Modals';
import { IconCross } from '../icons';
import './Modal.css';
import { revertTabTitle, setTabTitle } from 'utils/title';

interface ModalCommonProps {
  showModal: boolean;
  toggleModal: (bool: boolean) => void;
  content: React.FC<ModalProps>;
  title: string;
  id?: string;
}

export const Modal: FC<ModalCommonProps> = observer(
  ({ showModal, toggleModal, id, title, content }) => {
    const [clickStartedOutside, setClickStartedOutside] = useState(false);
    const [closing, toggleClosing] = useState(false);
    const [display, setDisplay] = useState(false);

    const useHookWithRefCallback = () => {
      const ref = useRef<null | any>(null);
      const setRef = useCallback((node: HTMLDivElement) => {
        const checkFocus = (e: KeyboardEvent) => {
          const current = e.target as HTMLElement;
          const shiftPressed = e.shiftKey;
          if (e.key === 'Tab') {
            e.stopPropagation();
            if (node && node.contains(current)) {
              const elements = node.querySelectorAll(
                'button, input:not([style*="display:none"]), textarea, select, div.select-selected'
              );

              if (!elements.length) {
                return;
              }

              let borderElem = shiftPressed
                ? elements[0]
                : elements[elements.length - 1];

              if (current.isEqualNode(borderElem)) {
                if (shiftPressed) {
                  (elements[elements.length - 1] as HTMLElement).focus();
                } else {
                  (elements[0] as HTMLElement).focus();
                }

                e.preventDefault();
                e.stopPropagation();
              } else {
                return true;
              }
            }
          }

          if (e.key === 'Escape' || e.key === 'Esc') {
            closeModal();
            e.stopPropagation();
          }

          return true;
        };

        const checkFocusGlobal = (e: KeyboardEvent) => {
          if (!ref.current) {
            document.removeEventListener('keydown', checkFocusGlobal);
            return;
          }
          if (e.key === 'Tab') {
            const elements = node.querySelectorAll(
              'button, input:not([style*="display:none"]), textarea, select, div.select-selected'
            );
            if (!elements.length) {
              return;
            }
            (elements[0] as HTMLElement).focus();
            e.preventDefault();
            e.stopPropagation();
          }

          if (e.key === 'Escape') {
            closeModal();
            e.stopPropagation();
          }

          return true;
        };

        if (ref.current) {
          ref.current.removeEventListener('keydown', checkFocus);
          document.removeEventListener('keydown', checkFocusGlobal);
        }

        if (node) {
          node.addEventListener('keydown', checkFocus);
          document.addEventListener('keydown', checkFocusGlobal);
          node.focus();
        }

        ref.current = node;
      }, []);

      return [setRef];
    };

    const [modalRef] = useHookWithRefCallback();

    useEffect(() => {
      if (showModal) {
        setTabTitle(`${title} | ${window.COMPANY} Client Portal`);
        setDisplay(true);
      } else {
        revertTabTitle();
        toggleClosing(true);
        setTimeout(() => {
          toggleClosing(false);
          setDisplay(false);
        }, 300);
      }
    }, [showModal]);

    const checkClickDown = (e: React.MouseEvent) => {
      if ((e.target as HTMLElement).id === id) {
        setClickStartedOutside(true);
      }
    };

    const checkClickUp = (e: React.MouseEvent) => {
      if (!clickStartedOutside) {
        return;
      }
      setClickStartedOutside(false);
      closeModal();
    };

    const closeModal = () => {
      modalCloseSubject.next(id);
      toggleModal(false);
    };

    const handleDragStart = () => {};
    const handleDragEnd = () => {};
    const handleDrag = () => {};

    const Component = content;

    if (!display) {
      return null;
    }

    return (
      <div
        tabIndex={-1}
        id={id}
        className={'modal' + (closing ? ' closing' : '')}
        onMouseDown={checkClickDown}
        onMouseUp={checkClickUp}
        ref={modalRef}
      >
        <div className='modal-wrapper'>
          <div
            className='titlebar'
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDrag={handleDrag}
          >
            <h2 className='modal-title'>{title}</h2>
            <button className='close-button' onClick={closeModal} aria-label={'Close ' + title}>
              <IconCross />
            </button>
          </div>
          <div className='content'>
            <Component showModal={display} closeModal={closeModal} />
          </div>
        </div>
      </div>
    );
  }
);
