import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { FC, useCallback, useContext, useEffect } from 'react';
import { DATE_FORMAT_SHORTER } from '../../../config';
import { actionsContext } from '../../../state/actionsState';
import { toastSubject } from '../../../state/rxjs';
import { Input } from '../../common/input/Input';
import { InputSelect } from '../../common/input/InputSelect';
import { TextField } from '../../common/input/TextField';
import { validator } from '../../common/input/validator';
import { PopupEvent } from '../../common/popup/Popup';
import { Spinner } from '../../common/spinner/Spinner';
import { ModalProps } from '../Modals';
import './PlaceOnHold.css';
import { SelectInfo } from './selectinfo/SelectInfo';
import { ActionSubmit } from './submit/ActionSubmit';
import validate from 'validate.js';
import { ValidationError } from '../../common/input/ValidationError';
import { useDots } from '../../../utils/hooks';
import { BlockMobile } from '../../common/blocks/Blocks';
import { SpacerWhite } from '../../common/blocks/Spacers';

export const PlaceOnHold: FC<ModalProps> = observer(
  ({ showModal, closeModal }) => {
    const AS = useContext(actionsContext);

    const formName = 'PlaceOnHoldModal';

    let debtorId: string = '';
    if (AS.selectedDebtors.size > 0) {
      debtorId = AS.selectedDebtors.keys().next().value.id;
    } else if (AS.selectedCases.size > 0) {
      debtorId = AS.selectedCases.keys().next().value.debtor.id;
    }

    const changeHoldCategory = action((value: string) => {
      AS.holdCategory = value;
    });

    const changeDays = action((e: React.FormEvent<HTMLInputElement>) => {
      AS.holdDays = e.currentTarget.value;
    });

    const changeComment = action((e: React.FormEvent<HTMLTextAreaElement>) => {
      AS.holdComments = e.currentTarget.value;
    });

    const loadingDots = useDots();

    const submit = useCallback(() => {
      if (AS.submitting) {
        return;
      }

      validator
        .validate(formName, true)
        .then(() => {
          AS.submitPlaceOnHold()
            .then(() => {
              toastSubject.next(PopupEvent.PLACE_ON_HOLD_SUCCESS);
              closeModal();
            })
            .catch((err) => {
              toastSubject.next(err.message);
            });
        })
        .catch(() => 0);
    }, [AS, closeModal]);

    useEffect(() => {
      if (showModal) {
        AS.getHoldCategores(debtorId);
      }
    }, [AS, showModal, debtorId]);

    if (!showModal) {
      return null;
    }

    return (
      <>
        <div>
          <SelectInfo />
          <h3>Details</h3>
          <div className="place-on-hold-days">
            <span className="input-label">Place on hold for</span>
            <Input
              properties={{ 'aria-label' : 'Enter number of days to hold' }}
              label=""
              width="short"
              value={AS.holdDays}
              name="holdDays"
              formName={formName}
              validateFunc={() =>
                validate.single(AS.holdDays, {
                  presence: { allowEmpty: false },
                  numericality: {
                    strict: true,
                    greaterThan: 0,
                    notGreaterThan: 'This field is required',
                  },
                })
              }
              options={{
                numeral: true,
                numeralPositiveOnly: true,
                numeralThousandsGroupStyle: 'none',
                numeralDecimalScale: 0,
              }}
              size={4}
              maxLength={4}
              onChange={changeDays}
              className="input-days"
            />
            <BlockMobile>
              <SpacerWhite offset="small"/>
            </BlockMobile>
            <span>days.</span>

            {AS.holdDays && AS.holdDays !== '0' ? (
              <span className="expires">
                Hold expires{' '}
                {moment().add(AS.holdDays, 'days').format(DATE_FORMAT_SHORTER)}
              </span>
            ) : null}
          </div>
          {AS.holdCategories ? (
            <InputSelect
              width="grow"
              formName={formName}
              options={AS.holdCategories}
              label="Hold category"
              placeholder="Select category"
              name="hold-category"
              selectedThing={AS.holdCategory}
              onChange={changeHoldCategory}
              validateFunc={() =>
                validate.single(AS.holdCategory, {
                  presence: {
                    allowEmpty: false,
                    message: 'Category is required',
                  },
                })
              }
            />
          ) : (
            <InputSelect
              width="grow"
              formName={formName}
              options={{}}
              label="Hold category"
              placeholder={'Loading' + loadingDots}
              name="hold-category"
              selectedThing={undefined}
              onChange={changeHoldCategory}
              validateFunc={() =>
                validate.single(AS.holdCategory, {
                  presence: {
                    allowEmpty: false,
                    message: 'Category is required',
                  },
                })
              }
            />
          )}
          <h3>Comments</h3>
          <TextField
            formName={formName}
            placeholder="Add a comment"
            name="comments"
            onChange={changeComment}
            value={AS.holdComments}
            className="wide"
            validateFunc={() =>
              validate.single(AS.holdComments, {
                presence: { allowEmpty: false },
              })
            }
          />
        </div>
        <ActionSubmit
          submitting={AS.submitting}
          cancel={closeModal}
          submit={submit}
          submitText="Place on hold"
        />
      </>
    );
  }
);
