import React, { FC, useRef } from 'react';
import { SortHeaderCell } from '../../common/table/SortHeaderCell';
import moment from 'moment';
import { DATE_FORMAT_SHORTER } from '../../../config';
import { formatNumber } from '../../../utils/formatNumber';

import { Button } from '../../common/button/Button';
import { GenericSorter } from '../../../state/genericSorter';
import { useHorizontalDragScroll } from '../../../utils/hooks';

import './PaymentsTable.css';
import { observer } from 'mobx-react';
import { Payment } from '../../../server-api/model';

export const PaymentsTable: FC<{
  sorter: GenericSorter<Payment>;
  count: number;
  getMore: () => void;
  loadingMore: boolean;
  labelledBy?: string;
}> = observer(({ sorter, count, getMore, loadingMore, labelledBy }) => {
  const ref = useRef<HTMLDivElement>(null);

  useHorizontalDragScroll(ref);

  return (
    <div className='payments-container' ref={ref}>
      <table className='payments__table table-v2' aria-labelledby={labelledBy}>
        <thead>
          <tr>
            <SortHeaderCell
              mode='td'
              sorter={sorter}
              type='paymentDate'
              text='Date'
              className='payment__date'
            />
            <SortHeaderCell
              mode='td'
              sorter={sorter}
              type='paymentType'
              text='Type'
              className='payment__Type'
            />
            <SortHeaderCell
              mode='td'
              sorter={sorter}
              type='paymentReference'
              text='Reference'
              className='payment__ref'
            />
            <SortHeaderCell
              mode='td'
              sorter={sorter}
              type='paymentRemittance'
              text='Remittance'
              className='payment__remitbatch'
            />
            <SortHeaderCell
              mode='td'
              sorter={sorter}
              type='paymentAmount'
              className='payment__amount'
              text='Amount'
            />
            <SortHeaderCell
              mode='td'
              sorter={sorter}
              type='paymentStatus'
              text='Status'
              className='payment__status'
            />
            <SortHeaderCell
              mode='td'
              className='payment__toclient'
              sorter={sorter}
              type='paymentToClient'
              text='To Client'
            />
            <SortHeaderCell
              mode='td'
              className='payment__tous'
              sorter={sorter}
              type='paymentToFees'
              text='To Fees'
            />
          </tr>
        </thead>
        <tbody>
          {sorter.sortedEntries.map((payment, index) => {
            if (!payment.payment) {
              return null;
            }
            return (
              <tr key={'payment' + index}>
                <td className='payment__date'>
                  {moment(payment.payment.date).format(DATE_FORMAT_SHORTER)}
                </td>
                <td className='payment__type'>{payment.payment.paymentType}</td>
                <td className='payment__ref'>
                  {payment.payment.paymentReference}
                </td>
                <td className='payment__remitbatch'>
                  {payment.payment.remittanceBatch &&
                    payment.payment.remittanceBatch.batchNumber}
                </td>
                <td className='payment__amount'>
                  £{formatNumber(payment.payment.amount)}
                </td>
                <td className='payment__status'>{payment.payment.status}</td>
                <td className='payment__toclient'>
                  £{formatNumber(payment.toClient)}
                </td>
                <td className='payment__tous'>£{formatNumber(payment.toUs)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {sorter.sortedEntries.length < count ? (
        <Button onClick={getMore} className='btn' loading={loadingMore}>
          Show more
        </Button>
      ) : null}
    </div>
  );
});
