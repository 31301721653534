import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DATE_TIME_FORMAT_SHORTER } from '../../config';
import { PortalUser, User } from '../../server-api/model';
import { GenericSorter } from '../../state/genericSorter';
import { userRolesReversed, usersContext } from '../../state/usersState';
import { useHorizontalDragScroll } from '../../utils/hooks';
import { Spinner } from '../common/spinner/Spinner';
import { RowWrapper } from '../common/table/Row';
import { SortHeaderCell } from '../common/table/SortHeaderCell';
import { FlatList } from '../searchresults/debtorview/FlatList';
import { UserInfo } from './UserInfo';
import './UserList.css';

export const UserList = observer(() => {
  return (
    <div className='UserList'>
      <UserTable />
    </div>
  );
});

const UserTable = observer(() => {
  const ref = useRef<HTMLDivElement>(null);
  const usersState = useContext(usersContext);
  useHorizontalDragScroll(ref);

  const selectionPool = usersState.selectedUsers;
  const users = usersState.users;
  const [sorter, setSorter] = useState(
    new GenericSorter(users, 'usersName', true)
  );

  useEffect(() => {
    setSorter(new GenericSorter(users, sorter.sortBy, sorter.sortAscending));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  return (
    <div role='group' aria-labelledby='manageUsersLabel'>
      <div role='table' className='UserTable' ref={ref} aria-labelledby='manageUsersLabel'>
        <div role='rowgroup'>
          <div role='row' className='header'>
            <div role='columnheader' className='cell-chevron'>
              <span>Expand</span>
            </div>
            <SortHeaderCell
              sorter={sorter}
              type='usersName'
              text='Name'
              className='cell-fullname'
            />
            <SortHeaderCell
              sorter={sorter}
              type='usersUsername'
              text='Username'
              className='cell-username'
            />
            <SortHeaderCell
              sorter={sorter}
              type='usersJob'
              text='Job title'
              className='cell-jobtitle'
            />
            <SortHeaderCell
              sorter={sorter}
              type='usersPermissions'
              text='Permissions'
              className='cell-permissions'
            />
            <SortHeaderCell
              sorter={sorter}
              type='usersAdmin'
              text='Administrator'
              className='cell-admin'
            />
            <SortHeaderCell
              sorter={sorter}
              type='usersLastlogin'
              text='Last login'
              className='cell-lastlogin'
            />
            <SortHeaderCell
              sorter={sorter}
              type='usersStatus'
              text='Status'
              className='cell-status'
            />
            <div role='columnheader' className='cell-checkbox'>
              <span>Select</span>
            </div>
          </div>
        </div>

        <div
          className={'users-list ' + (usersState.gettingUsers ? 'disabled' : '')}
          role='rowgroup'
        >
          <FlatList estimatedRowSize={40}>
            {sorter.sortedEntries.map((user: PortalUser, index) => {
              const cellsToShow = {
                fullname: user.fullName,
                username: user.login,
                jobtitle: user.position,
                permissions: userRolesReversed[user.role],
                admin: user.admin ? 'Yes' : 'No',
                lastLogin: user.lastLogin
                  ? moment(user.lastLogin).format(DATE_TIME_FORMAT_SHORTER)
                  : 'Never',
                status: user.active ? 'Active' : 'Inactive',
              };
              return (
                <RowWrapper
                  key={'row' + index}
                  item={user}
                  cellsToShow={cellsToShow}
                  expansion={<UserInfo user={user} />}
                  selectionPool={selectionPool}
                />
              );
            })}
          </FlatList>
        </div>
        {usersState.gettingUsers ? <Spinner blocking={true} /> : null}
      </div>
    </div>
  );
});
