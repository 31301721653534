import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { InputSelect } from "./InputSelect";
import "./DateChooser.css";
import DayPicker from "./daypicker";

export const DateChooser: FC<{
  onChange: (date: number) => void;
  value?: number | number[];
  to?: number;
  from?: number;
  disabledDays?: number[];
}> = ({ onChange, value, to, from, disabledDays }) => {
  const [month, setMonth] = useState<number | undefined>(undefined);
  const [keydownEnabled, setKeydownEnabled] = useState(true);

  const toggleYearMonthDropdown = (active: boolean) => {
    setKeydownEnabled(!active);
  }

  // get initial month from value
  useEffect(() => {
    if (!value) {
      setMonth(undefined);
      return;
    }
    if (typeof value === "number") {
      setMonth(value);
    } else if (!value?.length) {
      setMonth(undefined);
    } else {
      setMonth(value[value.length - 1]);
    }
  }, [value]);

  const correctDate = (date: number) => {
    const uncorrectedDate = moment(date);
    const utcOffsetMinutes = uncorrectedDate.utcOffset();
    if (utcOffsetMinutes > 0) {
      return uncorrectedDate.subtract(utcOffsetMinutes, "minutes").toDate();
    }
    return uncorrectedDate.add(utcOffsetMinutes, "minutes").toDate();
  };

  const getZoneCorrectedDate = (date?: number | number[]) => {
    if (!date) {
      return undefined;
    }
    if (typeof date === "number") {
      return correctDate(date);
    } else {
      if (!date.length) {
        return undefined;
      }
      return date.map((d) => correctDate(d));
    }
  };

  const disabled: any = disabledDays || [];
  if (from) {
    disabled.push({ before: getZoneCorrectedDate(from) });
  }
  if (to) {
    disabled.push({ after: getZoneCorrectedDate(to) });
  }

  return (
    <div className="DateChooser">
      <DayPicker
        disabledDays={disabled}
        onDayClick={(date) => onChange(date.getTime())}
        selectedDays={getZoneCorrectedDate(value)}
        showOutsideDays={true}
        weekdaysShort={["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]}
        month={month ? new Date(month) : undefined}
        captionElement={
          <YearMonthForm
            date={
              !value
                ? new Date()
                : typeof value === "number"
                ? new Date(value)
                : value.length
                ? new Date(value[value.length - 1])
                : new Date()
            }
            onChange={(month) => setMonth(month.getTime())}
            onToggle={toggleYearMonthDropdown}
          />
        }
        keydownEnabled={keydownEnabled}
      />
    </div>
  );
};

const currentYearDate = new Date().getFullYear();
const fromYearDate = new Date(currentYearDate - 10, 0);
const toYearDate = new Date(currentYearDate + 10, 11);
const years = {};
const monthsLabels = moment.localeData().months();
const months = {};

for (
  let i = fromYearDate.getFullYear();
  i <= toYearDate.getFullYear();
  i += 1
) {
  years[i] = i;
}

for (let i = 0; i < 12; i += 1) {
  months[monthsLabels[i]] = i;
}

interface YearMonthFormProps {
  date: Date;
  onChange: (date: Date) => any;
  onToggle: (active: boolean) => void;
}

export const YearMonthForm: FC<YearMonthFormProps> = ({ date, onChange, onToggle }) => {
  const handleChangeYear = function handleChange(val: any) {
    date.setFullYear(val);
    onChange(date);
  };

  const handleChangeMonth = function handleChange(val: any) {
    date.setMonth(val);
    onChange(date);
  };

  return (
    <div className="DayPicker-Caption">
      <div>
        <InputSelect
          name="monthsSelect"
          onChange={handleChangeMonth}
          options={months}
          selectedThing={date.getMonth()}
          onToggle={onToggle}
        />
        <InputSelect
          name="yearsSelect"
          onChange={handleChangeYear}
          options={years}
          selectedThing={
            date > toYearDate || date < fromYearDate
              ? undefined
              : date.getFullYear()
          }
          placeholder={date.getFullYear().toString()}
          onToggle={onToggle}
        />
      </div>
    </div>
  );
};
