import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { usersContext } from '../../state/usersState';
import { useTabTitle } from '../../utils/hooks';
import { Toggle } from '../common/toggle/Toggle';
import { ManageUsersButtons } from './Buttons';
import './ManageUsers.css';
import { UserList } from './UserList';

export const ManageUsers = observer(() => {
  useTabTitle(`Manage Users | ${window.COMPANY} Client Portal`);
  const usersState = useContext(usersContext);

  useEffect(() => {
    usersState.init();
  }, [usersState]);

  const getActionsText = () => {
    const numberSelectedUsers = usersState.selectedUsers.size;
    if (numberSelectedUsers === 0) {
      return <>No users selected.</>;
    } else if (numberSelectedUsers === 1) {
      return (
        <>
          <strong>1</strong> user selected.
        </>
      );
    } else {
      return (
        <>
          <strong>{numberSelectedUsers}</strong> users selected.
        </>
      );
    }
  };

  return (
    <div className='ManageUsers'>
      <div className='header-row'>
        <div />
        <h1 id='manageUsersLabel'>Manage Users</h1>
        <div className='switch-wrapper'>
          <Toggle
            checked={usersState.showInactiveUsers}
            onChange={usersState.toggleShowInactiveUsers}
            label='Show inactive users'
          />
        </div>
      </div>

      <h2 className='actions-header'>
        <strong>Actions</strong>
        <span>{getActionsText()}</span>
      </h2>

      <ManageUsersButtons />
      <UserList />
    </div>
  );
});
