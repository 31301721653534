import React, { FC, ReactNode } from "react";

export interface RadioOption {
  value: any;
  label: ReactNode;
}

type RadioProps = RadioOption & {
  id: string;
  name: string;
  checked?: boolean;
  onChange?: (value: React.FormEvent<HTMLInputElement>) => any;
};

export const Radio: FC<RadioProps> = ({
  name,
  id,
  value,
  label,
  checked,
  onChange
}) => {
  return (
    <div className={"Radio " + (checked ? "checked" : "")}>
      <label>
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          checked={checked}
        />
        <span className="radio-circle">
          <span className="radio-dot"></span>
        </span>
        <span>
          {label}
        </span>
      </label>
    </div>
  );
};
