import { observer } from 'mobx-react';
import React from 'react';

interface Props {
  caseView: boolean;
  onToggle: () => void;
}

const ViewToggler = observer(({caseView, onToggle}: Props) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    switch (e.code) {
      case 'Space':
      case 'Enter': {
        e.stopPropagation();
        e.preventDefault();
        onToggle();
      }
    }
  }

  return (
    <div className='view-switcher'
      tabIndex={0}
      onClick={onToggle}
      onKeyDown={handleKeyDown}
      role='button'
      aria-label={caseView ? 'Customer View' : 'Case View'}
    >
      <div className={caseView ? '' : 'active'}>Customer View</div>
      <div className={caseView ? 'active' : ''}>Case View</div>
    </div>
  );
});

export default ViewToggler;
