import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { COMMON_DEBOUNCE_DURATION, DATE_FORMAT_SHORTER } from '../../../config';
import { FilterChip, searchContext } from '../../../state/search/searchState';
import {
  useDetectMobileOverflow,
  useForceUpdate,
  useHorizontalDragScroll,
} from '../../../utils/hooks';
import { Button } from '../button/Button';
import { IconBookmark, IconCross, IconFilter } from '../icons';

export const ChipsInput: FC<{
  updateUrl: () => void;
  submitSearch: () => void;
}> = observer(({ updateUrl, submitSearch }) => {
  const [focusedChip] = useState<number | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const searchState = useContext(searchContext);

  useHorizontalDragScroll(containerRef);
  const forceUpdate = useForceUpdate();
  useDetectMobileOverflow(containerRef);

  useEffect(() => {
    scrollInputToEnd();
  }, [searchState.chips]);

  useEffect(() => {
    scrollInputToStart();
  }, []);

  useEffect(() => {
    forceUpdate();
  }, [searchState.filterOptionContracts, forceUpdate]);

  const handleDeleteChipClick = (index: number) => {
    searchState.removeChip(index);
  };

  const handleDeleteFilterChipClick = (chip: FilterChip) => {
    searchState.removeFilterChip(chip);
  };

  const scrollInputToEnd = () => {
    if (!containerRef.current) {
      return;
    }

    containerRef.current!.scrollLeft = 99999;
  };

  const scrollInputToStart = () => {
    if (!containerRef.current) {
      return;
    }

    containerRef.current!.scrollLeft = 0;
  };

  const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        submitSearch();
        inputRef.current!.blur();
        break;
      case 'Backspace':
        if (
          e.currentTarget.selectionStart === 0 &&
          e.currentTarget.selectionEnd === 0 &&
          searchState.chips.length
        ) {
          searchState.removeChip(searchState.chips.length - 1);
        }
        break;
      case ',':
        // searchState.tokenizeInput(e.currentTarget.value);
        break;
      default:
    }
  };

  const getSize = () => {
    if (searchState.inputValue.length <= 10) {
      return 10;
    }
    if (searchState.inputValue.length >= 50) {
      return 50;
    }
    return searchState.inputValue.length;
  };

  const checkName = (name: string) => {
    switch (name) {
      case 'JBW_REF':
        return window.COMPANY_LABEL + ' ref';
      default:
        return name;
    }
  };

  return (
    <div className='chips-container' ref={containerRef}>
      {Array.from(searchState.filterChips.entries()).map(
        ([name, chip], index) => {
          const { value, key } = chip;
          return (
            <div
              key={'chip' + index}
              className={
                'chip filter-chip' +
                (focusedChip === index ? ' active' : '') +
                (name === 'Bookmarks' ? ' bookmark' : '')
              }
            >
              {name === 'Bookmarks' ? <IconBookmark /> : <IconFilter />}
              <span style={{ marginLeft: '3px' }}>
                {checkName(name)}:{' '}
                {value !== undefined
                  ? key === 'dates'
                    ? moment(new Date(value as string)).format(
                        DATE_FORMAT_SHORTER
                      )
                    : typeof value === 'boolean'
                    ? value
                      ? 'Yes'
                      : 'No'
                    : typeof value === 'number'
                    ? value
                    : typeof value === 'string'
                    ? value
                    : null
                  : null}
              </span>
              <button
                className='cross'
                onClick={() => handleDeleteFilterChipClick(chip)}
                aria-label={'Delete filter ' + name}
              >
                <IconCross />
              </button>
            </div>
          );
        }
      )}
      {searchState.chips.map((chip, index) => {
        return (
          <div
            key={'chip' + index}
            className={'chip' + (focusedChip === index ? ' active' : '')}
          >
            <span>{chip}</span>
            <button
              className='cross'
              onClick={() => handleDeleteChipClick(index)}
              aria-label={'Delete filter ' + chip}
            >
              <IconCross />
            </button>
          </div>
        );
      })}

      <input
        tabIndex={0}
        ref={inputRef}
        size={getSize()}
        autoComplete='on'
        value={searchState.inputValue}
        onKeyDown={(e) => handleKey(e)}
        onFocus={scrollInputToEnd}
        onChange={(e) => searchState.changeInput(e.target.value)}
        onBlur={scrollInputToEnd}
        className={
          'searchbar-input ' +
          (searchState.chips.length > 0 || searchState.filterChips.size > 0
            ? 'less-padding'
            : '')
        }
        type='text'
        placeholder={
          searchState.chips.length > 0 || searchState.filterChips.size > 0
            ? ''
            : 'Quick search (enter Reference, VRM, Name or Postcode)'
        }
        aria-label='Quick search (enter Reference, VRM, Name or Postcode)'
      />
    </div>
  );
});

interface Chip {
  value: string;
}
