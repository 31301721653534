import React from "react";
import "./Toggle.css";
import { IconCheckmark } from "../icons";

interface Props {
  id?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => any;
  checked?: boolean;
  label?: string;
}

export const Toggle = ({ id, onChange, checked, label }: Props) => {
  return (
    <label className="switch">
      <span>{label}</span>
      <input id={id} type="checkbox" checked={checked} onChange={onChange}/>
      <span className="slider round">
        <span className="handle">
          <IconCheckmark />
        </span>
      </span>
    </label>
  );
};
