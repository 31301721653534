import { Button } from 'components/common/button/Button';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { DATE_FORMAT_SHORTER } from '../../../config';
import { Case, HistoryNote } from '../../../server-api/model';
import { GenericSorter } from '../../../state/genericSorter';
import { historyContext } from '../../../state/historyState';
import { useTabTitle } from '../../../utils/hooks';
import { CustomScrollbars } from '../../common/scrollbars/Scrollbars';
import { Spinner } from '../../common/spinner/Spinner';
import { SortHeaderCell } from '../../common/table/SortHeaderCell';
import './InfoHistory.css';
import { Sidebar } from './Sidebar';

export const InfoHistory = observer(() => {
  useTabTitle(`History | ${window.COMPANY} Client Portal`);
  const historyState = useContext(historyContext);

  let [sorter, setSorter] = useState(
    new GenericSorter(historyState.notes, 'date', false)
  );
  let [sortedNotes, setSortedNotes] = useState(sorter.sortedEntries);

  useEffect(() => {
    const newSorter = new GenericSorter(
      historyState.notes,
      historyState.sortBy,
      !historyState.desc
    );
    setSorter(newSorter);
    setSortedNotes(newSorter.sortedEntries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyState.notes]);

  useEffect(() => {
    const disposer = reaction(
      () => {
        return [sorter.sortAscending, sorter.sortBy, sorter.sortedEntries];
      },
      (data, reaction) => {
        setSortedNotes(sorter.sortedEntries);
        historyState.setSort(sorter.sortBy, true);
      }
    );
    return disposer;
  }, [sorter]);

  const checkCases = (cases: Case[]): boolean => {
    if (cases.length === 0 && historyState.caselessNotesChecked) {
      return true;
    }
    let gotCases = false;
    cases.forEach((caze) => {
      if (historyState.selectedCases.has(caze.oneStepId)) {
        gotCases = true;
      }
    });
    return gotCases;
  };

  const getHighlight = (entry: HistoryNote) => {
    if (historyState.filter) {
      return;
    }
    if (
      !historyState.selectedCases.size &&
      !historyState.caselessNotesChecked
    ) {
      if (
        historyState.selectedCategories.has(
          entry.noteType.clientPortalCategory.name
        )
      ) {
        return ' row-highlight';
      }
    } else if (!historyState.selectedCategories.size) {
      if (checkCases(entry.cases)) {
        return ' row-highlight';
      }
    } else {
      if (
        historyState.selectedCategories.has(
          entry.noteType.clientPortalCategory.name
        ) &&
        checkCases(entry.cases)
      ) {
        return ' row-highlight';
      }
    }

    return '';
  };

  if (historyState.gotMore) {
    sorter = {
      sortBy: historyState.sortBy,
      sortAscending: !historyState.desc,
      setSortBy: historyState.setSort,
    } as GenericSorter<any>;
    sortedNotes = historyState.notes;
  }

  return (
    <div className='InfoHistory'>
      <Sidebar />
      <div className='history-view'>
        <div role='table' className='table' aria-label='History'>
          <div role='rowgroup'>
            <div role='row' className='thead'>
              <SortHeaderCell
                sorter={sorter}
                className='history-cell-date'
                type='date'
                text='Date'
              />
              <SortHeaderCell
                sorter={sorter}
                className='history-cell-user'
                type='osCreatedBy'
                text='User'
              />
              <SortHeaderCell
                sorter={sorter}
                className='history-cell-type'
                type='noteType'
                text='Type'
              />
              <SortHeaderCell
                sorter={sorter}
                className='history-cell-desc'
                type='text'
                text='Description'
              />
            </div>
          </div>
          <div role='rowgroup' className='tbody'>
            <div style={{ display: 'block', width: '100%' }}>
              {sortedNotes.map((entry: HistoryNote, index) => {
                return (
                  <div
                    role='row'
                    key={'history-' + index}
                    className={'tr history-row ' + getHighlight(entry)}
                  >
                    <div role='cell' className='td history-cell-date'>
                      {moment(entry.date).format(DATE_FORMAT_SHORTER)}
                    </div>
                    <div role='cell' className='td history-cell-user'>
                      {entry.osCreatedBy}
                    </div>
                    <div role='cell' className='td history-cell-type'>
                      {entry.noteType && entry.noteType.caption}
                    </div>
                    <div role='cell' className='td history-cell-desc'>{entry.text}</div>
                  </div>
                );
              })}
            </div>
            {historyState.gotMore ? (
              <Button
                className='btn btn-primary'
                onClick={historyState.loadMore}
                style={{ margin: ' 10px auto', width: '150px' }}
                loading={historyState.loadingMoreHistory}
              >
                Show more
              </Button>
            ) : null}
          </div>
          {historyState.loadingHistory ? <Spinner blocking={true} /> : null}
        </div>
      </div>
    </div>
  );
});
