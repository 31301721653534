import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { __RouterContext } from 'react-router';
import { DATE_FORMAT_SHORTER } from '../../../config';
import { DEBTOR, DebtorRouteParams } from '../../../routes';
import { Case } from '../../../server-api/model';
import { actionsContext } from '../../../state/actionsState';
import { CaseSorter } from '../../../state/caseSorter';
import { caseContext } from '../../../state/caseState';
import { checkCazeClosed } from '../../../state/search/utils';
import { formatNumber } from '../../../utils/formatNumber';
import {
  useDetectMobileOverflow,
  useHorizontalDragScroll,
} from '../../../utils/hooks';
import { RowWrapper } from '../../common/table/Row';
import { SortHeaderCell } from '../../common/table/SortHeaderCell';
import { FlatList } from '../../searchresults/debtorview/FlatList';
import { CaseExpansion } from './CaseExpansion';

interface InfoCaseTableProps {
  entries: Case[];
  sorter: CaseSorter;
}
export const InfoCaseTable: FC<InfoCaseTableProps> = observer(
  ({ entries, sorter }) => {
    const actionsState = useContext(actionsContext);
    const routerState = useContext(__RouterContext);
    const caseState = useContext(caseContext);
    const tableRef = useRef<HTMLDivElement>(null);
    const cazeIdParam = (routerState.match.params as DebtorRouteParams).caze;
    useHorizontalDragScroll(tableRef);
    useDetectMobileOverflow(tableRef);
    const [scrollToIndex, setScrollToIndex] = useState<number>();

    const jsxEntries = useMemo(() => {
      return entries.map((caze) => {
        const cellsToShow = {
          caseRef: caze.clientReference,
          contract: caze.contract.name,
          warrant:
            caze.warrant && caze.warrant.warrantDate
              ? moment(caze.warrant.warrantDate).format(DATE_FORMAT_SHORTER)
              : '',
          originalDebt: '£' + (formatNumber(caze.debtOnly) || '0.00'),
          stage: checkCazeClosed(caze.status)
            ? ''
            : caze.lastStage && caze.lastStage.name
            ? caze.lastStage.name
            : '',
          vrm: caze.originalVrm ? caze.originalVrm : '',
          hold: caze.isOnHold ? 'Yes' : 'No',
          status: caze.status
            ? (
                caze.status.charAt(0).toUpperCase() +
                caze.status.slice(1).toLowerCase()
              ).replace(/_/g, ' ')
            : '',
          balance: '£' + formatNumber(caze.debtBalance),
        };

        const link = DEBTOR + '/' + caze.debtor.id + '/cases/' + caze.id;

        return (
          <RowWrapper
            cellsToShow={cellsToShow}
            bookmarks={true}
            key={'row-' + caze.id}
            item={caze}
            selectionPool={actionsState.selectedCases}
            link={link}
            expansion={<CaseExpansion caze={caze} />}
          />
        );
      });
    }, [entries, actionsState.selectedCases]);

    useEffect(() => {
      caseState.focusCase(cazeIdParam || null);
    }, [cazeIdParam]);

    useEffect(() => {
      if (cazeIdParam) {
        setTimeout(() => {
          for (const [index, caze] of entries.entries()) {
            if (caze.id === cazeIdParam) {
              setScrollToIndex(index);
            }
          }
        }, 500);
      }
    }, []);

    return (
      <div role='table' className='Table' ref={tableRef} aria-label='Cases'>
        <div role='rowgroup'>
          <div role='row' className='header'>
            <div role='columnheader' className='cell-chevron'>
              <span>Expand</span>
            </div>
            <div role='columnheader' className='cell-bookmark'>
              <span>Bookmark</span>
            </div>
            <SortHeaderCell
              sorter={sorter}
              type='clientReference'
              className='cell-caseref'
              text='Case Ref'
            />
            <SortHeaderCell
              sorter={sorter}
              type='contract'
              className='cell-contract'
              text='Contract'
            />
            <SortHeaderCell
              sorter={sorter}
              type='warrantDate'
              className='cell-warrant'
              text='Warrant/LO date'
            />
            <SortHeaderCell
              sorter={sorter}
              type='debtOnly'
              className='cell-originaldebt'
              text='Amount'
            />
            <SortHeaderCell
              sorter={sorter}
              type='stage'
              text='Stage'
              className='cell-stage'
            />
            <SortHeaderCell
              sorter={sorter}
              type='originalVrm'
              text='VRM'
              className='cell-vrm'
            />
            <SortHeaderCell
              sorter={sorter}
              className='cell-hold'
              type='isOnHold'
              text='Hold'
            />
            <SortHeaderCell
              sorter={sorter}
              className='cell-status'
              type='status'
              text='Status'
            />
            <SortHeaderCell
              sorter={sorter}
              className='cell-balance'
              type='debtBalance'
              text='Balance'
            />
            <div role='columnheader' className='cell-checkbox'>
              <span>Select</span>
            </div>
          </div>
        </div>
        <div role='rowgroup' className='table-body'>
          <FlatList scrollToIndex={scrollToIndex} estimatedRowSize={40}>
            {jsxEntries}
          </FlatList>
        </div>
      </div>
    );
  }
);
