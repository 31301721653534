import { CleaveOptions } from 'cleave.js/options';
import { action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { CONTACT_TYPE, REGEXPS } from '../../../config';
import { DebtorDto } from '../../../server-api/model';
import { actionsContext } from '../../../state/actionsState';
import { debtorContext } from '../../../state/debtorState';
import { toastSubject } from '../../../state/rxjs';
import { Button } from '../../common/button/Button';
import { Input } from '../../common/input/Input';
import { InputSelect } from '../../common/input/InputSelect';
import { TextField } from '../../common/input/TextField';
import { validator } from '../../common/input/validator';
import { PopupEvent } from '../../common/popup/Popup';
import { Spinner } from '../../common/spinner/Spinner';
import { ModalProps } from '../Modals';
import './AddContact.css';
import { SelectInfo } from './selectinfo/SelectInfo';
import { ActionSubmit } from './submit/ActionSubmit';
import validate from 'validate.js';

export const AddContact: FC<ModalProps> = observer(
  ({ showModal, closeModal }) => {
    const AS = useContext(actionsContext);
    const debtorState = useContext(debtorContext);
    const formName = 'AddContactModal';

    const [blockedVuln, blockVuln] = useState(false);
    const [blockedWarn, blockWarn] = useState(false);
    const [addressTouched, setAddressTouched] = useState(false);
    const [initialAddressState] = React.useState([
      AS.contactLine1,
      AS.contactLine2,
      AS.contactLine3,
      AS.contactLine4,
      AS.contactLine5,
      AS.contactLine6,
      AS.contactPostcode,
    ]);

    let debtor: DebtorDto | null = null;
    if (AS.selectedDebtors.size > 0) {
      debtor = AS.selectedDebtors.keys().next().value;
    } else if (AS.selectedCases.size > 0) {
      debtor = AS.selectedCases.keys().next().value.debtor;
    } else if (debtorState.loadedDebtor) {
      debtor = debtorState.loadedDebtor;
    }

    useEffect(() => {
      runInAction(() => {
        if (debtor) {
          AS.contactWarning = debtor.warning;
          AS.contactVulnerable = debtor.isVulnerable;
          blockWarn(debtor.warning || false);
          blockVuln(debtor.isVulnerable || false);
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debtor]);

    const checkTouched = () => {
      let different = false;
      [
        AS.contactLine1,
        AS.contactLine2,
        AS.contactLine3,
        AS.contactLine4,
        AS.contactLine5,
        AS.contactLine6,
        AS.contactPostcode,
      ].forEach((line, index) => {
        if (line !== initialAddressState[index]) {
          different = true;
        }
      });
      if (different) {
        setAddressTouched(true);
      } else {
        setAddressTouched(false);
      }
    };

    const changeComment = action((e: React.FormEvent<HTMLTextAreaElement>) => {
      AS.contactComments = e.currentTarget.value.length
        ? e.currentTarget.value
        : undefined;
    });

    const changeLine1 = action((e: React.FormEvent<HTMLInputElement>) => {
      AS.contactLine1 = e.currentTarget.value.length
        ? e.currentTarget.value
        : undefined;
      checkTouched();
    });

    const changeLine2 = action((e: React.FormEvent<HTMLInputElement>) => {
      AS.contactLine2 = e.currentTarget.value.length
        ? e.currentTarget.value
        : undefined;
      checkTouched();
    });

    const changeLine3 = action((e: React.FormEvent<HTMLInputElement>) => {
      AS.contactLine3 = e.currentTarget.value;
      checkTouched();
    });

    const changeLine4 = action((e: React.FormEvent<HTMLInputElement>) => {
      AS.contactLine4 = e.currentTarget.value.length
        ? e.currentTarget.value
        : undefined;
      checkTouched();
    });
    const changeLine5 = action((e: React.FormEvent<HTMLInputElement>) => {
      AS.contactLine5 = e.currentTarget.value.length
        ? e.currentTarget.value
        : undefined;
      checkTouched();
    });
    const changeLine6 = action((e: React.FormEvent<HTMLInputElement>) => {
      AS.contactLine6 = e.currentTarget.value.length
        ? e.currentTarget.value
        : undefined;
      checkTouched();
    });

    const changePostcode = action((e: React.FormEvent<HTMLInputElement>) => {
      AS.contactPostcode = e.currentTarget.value.length
        ? e.currentTarget.value
        : undefined;
      checkTouched();
    });

    const changeVulnerable = action(() => {
      if (blockedVuln) {
        return;
      }
      AS.contactVulnerable = !AS.contactVulnerable;
    });

    const changeWarning = action(() => {
      if (blockedWarn) {
        return;
      }
      AS.contactWarning = !AS.contactWarning;
    });

    const changeMobileEmailValue = action(
      (e: React.FormEvent<HTMLInputElement>, index: number) => {
        AS.mobilesEmails[index].value = e.currentTarget.value;
      }
    );

    const changeMobileEmailType = (value: string, index: number) =>
      runInAction(() => {
        if (value === 'REMOVE') {
          removeMobileEmail(index);
        } else {
          AS.mobilesEmails[index].type = value;
        }
      });

    const addMobileEmail = action(() => {
      AS.mobilesEmails.push({
        type: Object.values(AS.contactDetailsCategories || {})[0],
        value: undefined,
      });
    });

    const removeMobileEmail = action((index: number) => {
      AS.mobilesEmails.splice(index, 1);
    });

    const submit = useCallback(() => {
      if (AS.submitting) {
        return;
      }
      validator
        .validate(formName, true)
        .then(() => {
          AS.submitContact(debtor!.id)
            .then(() => {
              toastSubject.next(PopupEvent.ADD_CONTACT_SUCCESS);
              closeModal();
            })
            .catch((err) => {
              toastSubject.next(err.message);
            });
        })
        .catch(() => 0);
    }, [closeModal, AS, formName, debtor]);

    if (!showModal) {
      return null;
    }

    return (
      <>
        {AS.contactDetailsCategories ? null : <Spinner blocking={true} />}
        <div>
          <SelectInfo debtor={debtor} />
          <div className="flex-group">
            <div>
              <h3>New address</h3>
              <div>
                <Input
                  width="grow"
                  formName={addressTouched ? formName : undefined}
                  label="Address line 1"
                  value={AS.contactLine1}
                  onChange={changeLine1}
                  validateFunc={() =>
                    validate.single(AS.contactLine1, {
                      presence: { allowEmpty: false },
                    })
                  }
                  name="line1"
                />
                <Input
                  width="grow"
                  formName={addressTouched ? formName : undefined}
                  label="Address line 2"
                  value={AS.contactLine2}
                  onChange={changeLine2}
                  validateFunc={() =>
                    validate.single(AS.contactLine2, {
                      presence: { allowEmpty: false },
                    })
                  }
                  name="line2"
                />

                <Input
                  width="grow"
                  label="Address line 3"
                  value={AS.contactLine3}
                  onChange={changeLine3}
                  name="line3"
                />

                <Input
                  width="grow"
                  label="Address line 4"
                  value={AS.contactLine4}
                  onChange={changeLine4}
                  name="line4"
                />
                <Input
                  width="grow"
                  label="Address line 5"
                  value={AS.contactLine5}
                  onChange={changeLine5}
                  name="line5"
                />
                <Input
                  width="grow"
                  label="Address line 6"
                  value={AS.contactLine6}
                  onChange={changeLine6}
                  name="line6"
                />
                <Input
                  width="grow"
                  validateFunc={() =>
                    validate.single(AS.contactPostcode, {
                      presence: { allowEmpty: false },
                      format: {
                        pattern: REGEXPS.POSTCODE,
                        message: 'Please enter a UK postcode',
                      },
                    })
                  }
                  formName={addressTouched ? formName : undefined}
                  label="Postcode"
                  value={AS.contactPostcode && AS.contactPostcode.toUpperCase()}
                  onChange={changePostcode}
                  name="postcode"
                />
              </div>
            </div>
            <div>
              <h3>Other contact details</h3>
              <div>
                {AS.mobilesEmails.map((entry, index) => {
                  const rules =
                    entry.type === CONTACT_TYPE.EMAIL
                      ? {
                          email: {
                            message: 'This email address is not valid.\nExample: \'user@domain.com\'',
                          },
                        }
                      : entry.type === CONTACT_TYPE.MOBILE
                      ? {
                          format: {
                            pattern: REGEXPS.MOBILE_UK,
                            message: 'This phone number is not valid.\nExample: \'070 1234 0000\'',
                          },
                        }
                      : entry.type === CONTACT_TYPE.HOME
                      ? {
                          format: {
                            pattern: REGEXPS.HOME_UK,
                            message: 'This phone number is not valid.\nExample: \'020 1234 0000\'',
                          },
                        }
                      : entry.type === CONTACT_TYPE.OFFICE
                      ? {
                          format: {
                            pattern: REGEXPS.ALL_UK,
                            message: 'This phone number is not valid.\nExample: \'020 1234 0000\'',
                          },
                        }
                      : {};
                  const cleaveOptions: CleaveOptions | undefined =
                    entry.type === CONTACT_TYPE.MOBILE
                      ? {
                          phone: true,
                          phoneRegionCode: 'GB',
                        }
                      : entry.type === CONTACT_TYPE.HOME
                      ? {
                          phone: true,
                          phoneRegionCode: 'GB',
                        }
                      : entry.type === CONTACT_TYPE.OFFICE
                      ? {
                          phone: true,
                          phoneRegionCode: 'GB',
                        }
                      : undefined;
                  return (
                    <div
                      className="mobile-email-group"
                      key={'mobile-email-' + index + entry.type}
                    >
                      <InputSelect
                        width="compact"
                        name="selectContactDetailsCategories"
                        options={AS.contactDetailsCategories}
                        selectedThing={entry.type}
                        onChange={(value) =>
                          changeMobileEmailType(value, index)
                        }
                      />

                      <Input
                        properties={{ 'aria-label': 'Enter contact' }}
                        width="grow"
                        value={entry.value || ''}
                        options={cleaveOptions}
                        formName={
                          entry.value && entry.value.length
                            ? formName
                            : undefined
                        }
                        validateFunc={() => validate.single(entry.value, rules)}
                        name={'mobileEmail' + index}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                          changeMobileEmailValue(e, index)
                        }
                      />
                    </div>
                  );
                })}
                <Button className="btn add-more" onClick={addMobileEmail}>
                  Add more
                </Button>
              </div>
              <div className="blue-separator" />
              <div>
                <div className="flag-toggle-group">
                  <label>Turn on vulnerable customer flag?</label>

                  <Button
                    id="toggle-vulnerable"
                    mode={AS.contactVulnerable ? 'danger' : 'outline'}
                    disabled={blockedVuln}
                    onClick={changeVulnerable}
                  >
                    {AS.contactVulnerable ? 'Yes' : 'No'}
                  </Button>
                </div>
                <div className="flag-toggle-group">
                  <label>Turn on customer warning flag?</label>
                  <Button
                    mode={AS.contactWarning ? 'danger' : 'outline'}
                    disabled={blockedWarn}
                    onClick={changeWarning}
                    id="toggle-warning"
                  >
                    {AS.contactWarning ? 'Yes' : 'No'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <h3>Comments</h3>
          <TextField
            placeholder="Add a comment"
            name="comments"
            onChange={changeComment}
            value={AS.contactComments}
            className="wide"
          />
        </div>
        <ActionSubmit
          submitting={AS.submitting}
          cancel={closeModal}
          submit={submit}
          submitText="Add contact details"
        />
      </>
    );
  }
);
