import { createBrowserHistory } from 'history';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Router,
  Switch,
  __RouterContext,
} from 'react-router';
import { COMPANIES, COMPANY_LABELS, DIVISION } from '../config';
import {
  DEBTOR_ID,
  DEBTOR_TAB,
  EXPIRED,
  LOGIN,
  MANAGE_USERS,
  RESET_PASSWORD_CONFIRM,
  SEARCH,
} from '../routes';
import { appContext } from '../state/appState';
import { profileContext } from '../state/profileState';
import { detectIE } from '../utils/detectIE';
import './App.css';
import { AdminRoute } from './common/AdminRoute';
import { Popup } from './common/popup/Popup';
import { PrivateRoute } from './common/PrivateRoute';
import { PublicRoute } from './common/PublicRoute';
import { BigSpinner } from './common/spinner/BigSpinner';
import { Spinner } from './common/spinner/Spinner';
import { Dashboard } from './dashboard/Dashboard';
import { Header } from './header/Header';
import { Info } from './info/Info';
import { Login } from './login/Login';
import { ManageUsers } from './manageusers/ManageUsers';
import { Modals } from './modals/Modals';
import { Prompts } from './modals/Prompts';
import { NotFound } from './NotFound';
import { SearchResults } from './searchresults/SearchResults';
import { useResize } from '../utils/hooks';
import { ModalsV2 } from './modals/ModalsV2';

const App: React.FC = (props) => {
  const onResize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  useResize(onResize);

  const history = createBrowserHistory();
  return (
    <Router history={history}>
      <Route path='/' component={AppContent} />
    </Router>
  );
};

declare global {
  interface Window {
    COMPANY: COMPANIES;
    COMPANY_LABEL: COMPANY_LABELS;
    THEME: DIVISION;
    isMobile: boolean;
    titleHistory: { title: string }[];
  }
}

const AppContent: React.FC<RouteComponentProps> = observer((props) => {
  const profileState = React.useContext(profileContext);
  const appState = React.useContext(appContext);
  const routerState = React.useContext(__RouterContext);
  const [isIE] = React.useState(detectIE());
  const [fouc, setFouc] = React.useState(true);

  React.useEffect(() => {
    setFouc(false);
  }, []);

  React.useEffect(() => {
    if (profileState.passwordExpired && profileState.authenticated) {
      routerState.history.push(EXPIRED);
      runInAction(() => {
        profileState.passwordExpiredLogin = profileState.profileData!.login;
      });
    }
    // don't put routerState into dependencies! it causes an effect loop!
  }, [profileState.passwordExpired, profileState.authenticated]);

  if (appState.loadingTheme || !profileState.initialized) {
    return (
      <>
        <Spinner gray centered={true} />
      </>
    );
  }

  return (
    <div className={'AppRoot' + (isIE ? ' ie' : '') + (fouc ? ' fouc' : '')}>
      <div className='AppModals'>
        <Modals />
        <ModalsV2 />
        <Prompts />
        <Popup />
      </div>
      <BigSpinner />
      <div className='App'>
        {profileState.initialized && profileState.authenticated ? (
          <>
            <Header />
          </>
        ) : null}

        <main
          className={
            'Main ' +
            (props.location.pathname.indexOf('login') > -1 ? 'no-header' : '')
          }
          id='main'
        >
          <Switch>
            <Route
              exact={true}
              path={RESET_PASSWORD_CONFIRM}
              component={Login}
            />
            <PublicRoute exact={true} path={EXPIRED} component={Login} />
            <PublicRoute path={LOGIN} component={Login} />
            <PrivateRoute exact={true} path='/' component={Dashboard} />
            <PrivateRoute
              exact={true}
              path={SEARCH}
              component={SearchResults}
            />
            <PrivateRoute path={DEBTOR_TAB} component={ScrollToTopInfo} />
            <Redirect from={DEBTOR_ID} to={DEBTOR_ID + '/details'} />
            <AdminRoute
              exact={true}
              path={MANAGE_USERS}
              component={ManageUsers}
            />

            <PrivateRoute component={NotFound} />
          </Switch>
        </main>
      </div>
    </div>
  );
});

const ScrollToTopInfo: React.FC = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <Info />;
};

export interface RouteInfo {
  path: string;
}

export default App;
