import React, { FC, useRef, useState } from 'react';
import RSC from 'react-scrollbars-custom';
import './Scrollbars.css';

interface CustomScrollbarsProps {
  style?: React.CSSProperties;
  containerStyle?: any;
  shadow?: boolean;
  scrollbarStyle?: any;
  contentStyle?: any;
  scrollWrapperStyle?: any;
  translateContentSize?: boolean;
  horizontal?: true;
  vertical?: true;
  compensateWidth?: boolean;
  disable?: boolean;
}

export const CustomScrollbars: FC<CustomScrollbarsProps> = ({
  style: scrollboxStyle = {},
  containerStyle = {
    position: 'relative' as 'relative',
    height: '100%',
  },
  shadow,
  children,
  scrollbarStyle,
  contentStyle,
  scrollWrapperStyle,
  translateContentSize,
  compensateWidth,
  horizontal,
  vertical,
  disable,
}) => {
  const [isActive, setActive] = useState(false);
  const shadowTop = useRef<HTMLDivElement>(null);
  const shadowBottom = useRef<HTMLDivElement>(null);

  const onPointerDown = () => {
    setActive(true);
    document.addEventListener('pointerup', onPointerUp);
  };

  const onPointerUp = () => {
    setActive(false);
    document.removeEventListener('pointerup', onPointerUp);
  };

  const handleUpdate = (values: any) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    if (scrollHeight === clientHeight) {
      if (shadowTop.current) {
        shadowTop.current!.style.opacity = '0';
      }
      if (shadowBottom.current) {
        shadowBottom.current!.style.opacity = '0';
      }
      return;
    }
    const shadowTopOpacity = (1 / 20) * Math.min(scrollTop, 20);
    const bottomScrollTop = scrollHeight - clientHeight;
    const shadowBottomOpacity =
      (1 / 20) * (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - 20));
    if (shadowTop.current) {
      shadowTop.current!.style.opacity = shadowTopOpacity.toString();
    }
    if (shadowBottom.current) {
      shadowBottom.current!.style.opacity = shadowBottomOpacity.toString();
    }
  };

  const shadowTopStyle = {
    pointerEvents: 'none' as 'none', // wut
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 15,
    background:
      'linear-gradient(to bottom, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.02) 50%, rgba(0, 0, 0, 0) 100%)',
  };
  const shadowBottomStyle = {
    pointerEvents: 'none' as 'none',
    position: 'absolute' as 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 15,
    background:
      'linear-gradient(to top, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.02) 50%, rgba(0, 0, 0, 0) 100%)',
  };

  if (disable) {
    return <>{children}</>;
  }

  return (
    <div className='scrollbars__container' style={{ ...containerStyle }}>
      <RSC
        noScrollX={horizontal ? false : true}
        noScrollY={horizontal && !vertical ? true : false}
        disableTrackYWidthCompensation={compensateWidth ? undefined : true}
        translateContentSizeYToHolder={translateContentSize}
        onUpdate={handleUpdate}
        momentum
        style={{ ...scrollboxStyle }}
        trackXProps={{
          renderer: (props) => {
            const { elementRef, style, ...restProps } = props;
            delete restProps['axis']; // hack to prevent "axis" attribute to be rendered on div
            return (
              <div
                {...restProps}
                style={{
                  ...style,
                  width: '100%',
                  ...scrollbarStyle,
                  background: 'transparent',
                  boxSizing: 'border-box',
                  display: horizontal ? undefined : 'none',
                }}
                ref={elementRef}
                className={
                  'scrollbarx-track' + (isActive ? ' track--active' : '')
                }
              />
            );
          },
        }}
        trackYProps={{
          renderer: (props) => {
            const { elementRef, style, ...restProps } = props;
            delete restProps['axis'];
            return (
              <div
                {...restProps}
                style={{
                  ...style,
                  height: '100%',
                  ...scrollbarStyle,
                  background: 'transparent',
                  boxSizing: 'border-box',
                  display: horizontal ? 'none' : undefined,
                }}
                ref={elementRef}
                className={
                  'scrollbary-track' + (isActive ? ' track--active' : '')
                }
              />
            );
          },
        }}
        contentProps={{
          renderer: (props) => {
            const { elementRef, style, ...restProps } = props;
            return (
              <div
                {...restProps}
                style={{ ...style, ...contentStyle }}
                ref={elementRef}
                className='scrollbar-content'
              />
            );
          },
        }}
        wrapperProps={{
          renderer: (props) => {
            const { elementRef, style, ...restProps } = props;
            return (
              <div
                {...restProps}
                style={{ ...style, ...scrollWrapperStyle }}
                ref={elementRef}
                className='scrollbar-wrapper'
              />
            );
          },
        }}
        thumbYProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            delete restProps['axis'];
            return (
              <div
                {...restProps}
                ref={elementRef}
                className='scrollbar-thumbnail'
                onPointerDown={onPointerDown}
              />
            );
          },
        }}
        thumbXProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            delete restProps['axis'];
            return (
              <div
                {...restProps}
                ref={elementRef}
                className='scrollbar-thumbnail'
                onPointerDown={onPointerDown}
              />
            );
          },
        }}
      >
        {children}
      </RSC>
      {shadow ? (
        <>
          <div ref={shadowTop} style={shadowTopStyle} />
          <div ref={shadowBottom} style={shadowBottomStyle} />
        </>
      ) : null}
    </div>
  );
};
