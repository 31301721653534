import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext, useRef } from 'react';
import { historyContext } from '../../../state/historyState';
import { useFixator } from '../../../utils/hooks';
import { Checkbox } from '../../common/checkbox/Checkbox';
import { CustomScrollbars } from '../../common/scrollbars/Scrollbars';
import { ToggleWide } from '../../common/togglewide/ToggleWide';
import './Sidebar.css';

export const Sidebar = observer(() => {
  const historyState = useContext(historyContext);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useFixator(sidebarRef, containerRef, {
    top: 0,
    topBuffer: 470,
    ieOnly: true,
  });

  return (
    <div className='sidebar' ref={sidebarRef}>
      <div className='sidebar__container' ref={containerRef}>
        <div className='sidebar-view'>
          <h2>View</h2>
          <ToggleWide
            properties={{ 'aria-label': historyState.filter ? 'Highlight' : 'Filter' }}
            textOn='Highlight'
            textOff='Filter'
            checked={historyState.filter}
            onChange={historyState.toggleFilter}
          />
        </div>
        <CustomScrollbars
          scrollbarStyle={{
            right: '-16px',
            marginTop: '-2px',
            height: 'calc(100% + 2px)',
          }}
          shadow
        >
          <div className='sidebar-categories'>
            <h2>Categories</h2>
            <button
              className='btn-link'
              onClick={historyState.clearFilterCategories}
            >
              Clear filter
            </button>
            <div className='categories-checkboxes'>
              {historyState.categories.map(({ name, count }, index) => {
                return (
                  <Checkbox
                    inverse={true}
                    label={name + ' (' + count + ')'}
                    checked={historyState.selectedCategories.has(name)}
                    onChange={(e) => historyState.toggleCategory(name)}
                    key={'category-' + index}
                  />
                );
              })}
            </div>
          </div>
          <div className='sidebar-cases'>
            <h2>Cases</h2>
            <button className='btn-link' onClick={historyState.clearFilterCases}>
              Clear filter
            </button>
            <div className='customer-level-checkbox'>
              <Checkbox
                inverse={true}
                label={
                  'Customer level notes' +
                  ' (' +
                  historyState.caselessNotesAmount +
                  ')'
                }
                checked={historyState.caselessNotesChecked}
                onChange={historyState.toggleCustomerLevelNotes}
              />
            </div>
            <div className='cases-checkboxes'>
              {historyState.cases.map((caze, index) => {
                return (
                  <Checkbox
                    tooltip={
                      caze.clientReference +
                      (caze &&
                      (caze.warrantDate ||
                        caze.liabilityOrderDate ||
                        caze.dueDate)
                        ? ' - ' +
                          moment(
                            caze.warrantDate ||
                              caze.liabilityOrderDate ||
                              caze.dueDate
                          ).format('MMYY')
                        : '') +
                      ' (' +
                      caze.count +
                      ')'
                    }
                    inverse={true}
                    label={
                      <span className='case-row'>
                        <span className='case-ref'>{caze.clientReference}</span>
                        {caze &&
                        (caze.warrantDate ||
                          caze.liabilityOrderDate ||
                          caze.dueDate) ? (
                          <span className='case-date'>
                            {' - '}
                            {moment(
                              caze.warrantDate ||
                                caze.liabilityOrderDate ||
                                caze.dueDate
                            ).format('MMYY')}{' '}
                          </span>
                        ) : (
                          ''
                        )}
                        <span className='case-count'>
                          {' (' + caze.count + ')'}
                        </span>
                      </span>
                    }
                    checked={historyState.selectedCases.has(caze.oneStepId)}
                    onChange={(e) => historyState.toggleCase(caze.oneStepId)}
                    key={'case-' + index}
                  />
                );
              })}
            </div>
          </div>
        </CustomScrollbars>
      </div>
    </div>
  );
});
