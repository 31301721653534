import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useContext } from "react";
import validate from "validate.js";
import { actionsContext } from "../../../state/actionsState";
import { filesContext } from "../../../state/filesState";
import { useKeys } from "../../../utils/hooks";
import { Button } from "../../common/button/Button";
import { InputFile } from "../../common/input/InputFile";
import { InputSelect } from "../../common/input/InputSelect";
import { TextField } from "../../common/input/TextField";
import { validator } from "../../common/input/validator";
import { Spinner } from "../../common/spinner/Spinner";
import { ModalProps } from "../../modals/Modals";

export const FilesUploadFileModal: FC<ModalProps> = observer(
  ({ showModal, closeModal }) => {
    const filesState = useContext(filesContext);
    const AS = useContext(actionsContext);

    const formName = "FilesUploadFile";

    const changeCategory = (category: string) => {
      filesState.fileCategory = category;
    };

    const changeComment = (e: React.FormEvent<HTMLTextAreaElement>) => {
      runInAction(() => {
        filesState.fileComment = e.currentTarget.value;
      });
    };

    const submit = useCallback(() => {
      if (filesState.uploading) {
        return;
      }
      validator
        .validate(formName, true)
        .then(() => {
          filesState.uploadFiles().then(() => {
            closeModal();
          });
        })
        .catch(() => 0);
    }, [filesState, closeModal]);

    useKeys(["Enter"], [submit], !showModal);

    if (!showModal) {
      return null;
    }

    return (
      <>
        {AS.fileCategories ? null : <Spinner blocking />}
        <div style={{ position: "relative" }}>
          <h3>Select File</h3>

          <InputFile
            name="letters-file"
            formName={formName}
            value={filesState.droppedFile}
            label="Select file"
            validateFunc={() =>
              validate.single(filesState.droppedFile, {
                presence: { allowEmpty: false },
              })
            }
            onChange={(file) =>
              runInAction(() => {
                filesState.droppedFile = file;
              })
            }
          />

          <InputSelect
            formName={formName}
            validateFunc={() =>
              validate.single(filesState.fileCategory, {
                presence: { allowEmpty: false },
              })
            }
            options={AS.fileCategories}
            label="File category"
            placeholder="Select category"
            name="file-category"
            selectedThing={filesState.fileCategory}
            onChange={changeCategory}
          />
          <h3>Comments</h3>
          <TextField
            placeholder="Add a comment"
            name="comments"
            onChange={changeComment}
            value={filesState.fileComment}
            className="wide"
          />
          {filesState.uploading ? <Spinner blocking noSpinner /> : null}
        </div>
        <div className="modal-actions">
          <Button onClick={closeModal} className="btn btn-gray">
            Cancel
          </Button>
          <Button
            onClick={submit}
            className="btn btn-success"
            loading={filesState.uploading}
          >
            Upload file
          </Button>
        </div>
      </>
    );
  }
);

/* 
const acceptedFileTypes = `
 .doc,
 .docx,
 application/msword,
 application/vnd.openxmlformats-officedocument.wordprocessingml.document,
 application/pdf, 
 image/png,
 image/jpeg, 
 image/tiff,
 image/gif, 
 image/bmp
 `;
 */
