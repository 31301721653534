import {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { getScrollbarWidth } from './getScrollbarWidth';
import {
  formValidatedSubject,
  validator,
} from '../components/common/input/validator';
import { filter, debounceTime } from 'rxjs/operators';
import useKey from 'react-use/lib/useKey';
import { useUpdateEffect, useWindowSize } from 'react-use';
import { setTabTitle } from './title';
import { getOffset } from './getOffset';
import { detectIE } from './detectIE';

let eventTimeout: null | NodeJS.Timeout = null;

export const useOnKeySelect = (
  active: boolean,
  containerRef: RefObject<HTMLDivElement>,
  selector: string,
  focusElementSelector?: string
) => {
  const onKeySelect = useCallback(
    (e: KeyboardEvent) => {
      if (!active || !containerRef.current) {
        return;
      }

      const target = e.target as HTMLElement;
      if (e.key === ' ' && target.tagName === 'INPUT') {
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      if (e.key === 'Escape') {
        target.blur();
        return;
      }

      const elements = containerRef.current.querySelectorAll(
        selector
      ) as NodeListOf<HTMLElement>;

      if (!elements.length) {
        return;
      }

      if (!containerRef.current.contains(target)) {
        let elementToFocus: HTMLElement | undefined = undefined
        if (focusElementSelector) {
          elementToFocus = containerRef.current.querySelector(focusElementSelector) as HTMLElement
        }
        if (!elementToFocus) {
          elementToFocus = elements[0]
        }
        elementToFocus.focus()
        return;
      }

      let curIdx = 0;
      for (const [idx, element] of elements.entries()) {
        if (element.isEqualNode(target)) {
          curIdx = idx;
          break;
        }
      }

      const shiftPressed = e.shiftKey;

      switch (e.key) {
        case 'ArrowDown':
          if (curIdx + 1 < elements.length) {
            elements[curIdx + 1].focus();
          } else {
            elements[0].focus();
          }
          break;
        case 'ArrowUp':
          if (curIdx - 1 >= 0) {
            elements[curIdx - 1].focus();
          } else {
            elements[elements.length - 1].focus();
          }
          break;
        case 'Tab':
          if (shiftPressed) {
            if (curIdx - 1 >= 0) {
              elements[curIdx - 1].focus();
            } else {
              elements[elements.length - 1].focus();
            }
            break;
          }
          if (curIdx + 1 < elements.length) {
            elements[curIdx + 1].focus();
          } else {
            elements[0].focus();
          }
          break;
        case 'Enter':
          target.click();
          break;
        case ' ':
          target.click();
          break;
      }
    },
    [active, containerRef]
  );

  useKey('Escape', onKeySelect, undefined, [active, containerRef]);
  useKey('ArrowDown', onKeySelect, undefined, [active, containerRef]);
  useKey('ArrowUp', onKeySelect, undefined, [active, containerRef]);
  useKey('Enter', onKeySelect, undefined, [active, containerRef]);
  useKey(' ', onKeySelect, undefined, [active, containerRef]); // " " - big brain moment
};

export function useDots() {
  const [dots, setDots] = useState('');

  const updateDots = useCallback(() => {
    setDots(dots.length > 2 ? '' : dots + '.');
  }, [dots]);

  useInterval(() => {
    updateDots();
  }, 200);

  return dots;
}

export const useInterval = (callback: () => any, delay: number | null) => {
  const savedCallback = useRef<() => any>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current!();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return () => {};
  }, [delay]);
};

export function useImages(imagesToLoad: string[]): {
  [key: string]: {
    base64: string;
    width: number;
    height: number;
  } | null;
} {
  const toObj = (arr: string[]) => {
    let obj: {
      [key: string]: {
        base64: string;
        width: number;
        height: number;
      } | null;
    } = {};
    for (const iterator of arr) {
      obj[iterator] = null;
    }
    return obj;
  };

  const imagesToLoadRef = useRef(imagesToLoad);
  const imagesRef = useRef(toObj(imagesToLoad));
  const [images, setImages] = useState(toObj(imagesToLoad));

  const getBase64Image = (img: HTMLImageElement) => {
    var canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d')!;
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL('image/png');
    //return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    return dataURL;
  };

  useEffect(() => {
    imagesToLoadRef.current.forEach((image) => {
      const newImage = new Image();
      newImage.onload = () => {
        imagesRef.current = {
          ...imagesRef.current,
          [image]: {
            width: newImage.width,
            height: newImage.height,
            base64: getBase64Image(newImage),
          },
        };
        setImages(imagesRef.current);
      };
      newImage.src = image;
    });
  }, []);

  return images;
}

export function useValidation(
  formId: string
): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  (showErrors?: boolean) => Promise<unknown>
] {
  const [valid, setValid] = useState(false);

  useEffect(() => {
    const sub = formValidatedSubject
      .pipe(debounceTime(100))
      .pipe(
        filter(({ formId: validationFormId }) => validationFormId === formId)
      )
      .subscribe(({ isValid }) => {
        setValid(isValid);
      });
    return () => {
      sub.unsubscribe();
    };
  }, [formId]);

  const validateForm = (showErrors?: boolean) => {
    return validator.validate(formId, showErrors);
  };

  return [valid, setValid, validateForm];
}

export function useScroll(
  func: (e?: Event) => void,
  options?: { ignoreResize?: boolean }
) {
  useEffect(() => {
    window.addEventListener('scroll', func);
    if (!options?.ignoreResize) {
      window.addEventListener('resize', func);
    }

    return () => {
      window.removeEventListener('scroll', func);
      window.removeEventListener('resize', func);
    };
  }, [func]);
}

export function useResize(func: (e?: Event) => void) {
  useEffect(() => {
    const funcDebounced = () =>
      requestAnimationFrame(() => {
        func();
      });

    window.addEventListener('resize', funcDebounced);

    return () => {
      window.removeEventListener('resize', funcDebounced);
    };
  }, [func]);
}

export const useTraceUpdate = (props: any) => {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
};

export const useKeys = (
  keys: string[],
  actions: Array<() => void>,
  detach?: boolean,
  async?: boolean,
  ignoreShift?: true,
  ignoreAlt?: true,
  ignore?: boolean
) => {
  const checkKeys = useCallback(
    (e: KeyboardEvent) => {
      if (ignore) {
        return;
      }
      if (e.altKey && !ignoreAlt) {
        return;
      }
      if (e.shiftKey && !ignoreShift) {
        return;
      }
      if (!async) {
        if (eventTimeout) {
          clearTimeout(eventTimeout);
        }
        eventTimeout = setTimeout(() => {
          keys.forEach((key, index) => {
            if (e.key === key) {
              e.preventDefault();
              e.stopPropagation();
              actions[index]();
            }
          });
        }, 0);
      } else {
        keys.forEach((key, index) => {
          if (e.key === key) {
            e.preventDefault();
            e.stopPropagation();
            actions[index]();
          }
        });
      }
    },
    [keys, actions, async, ignoreAlt, ignoreShift]
  );

  useEffect(() => {
    if (detach) {
      window.removeEventListener('keydown', checkKeys);
    } else {
      window.addEventListener('keydown', checkKeys);
    }
    return () => {
      window.removeEventListener('keydown', checkKeys);
    };
  }, [detach, checkKeys]);
};

export function useForceUpdate(): () => void {
  const [, dispatch] = useState<{}>(Object.create(null));

  const memoizedDispatch = useCallback((): void => {
    dispatch(Object.create(null));
  }, [dispatch]);
  return memoizedDispatch;
}

export const useHorizontalDragScroll = (ref: React.RefObject<HTMLElement>) => {
  let dragging = false;
  let clickX = 0;
  let lastLeft = 0;

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const element = ref.current;
    element.addEventListener('mousedown', handleDown);
    return () => {
      if (!element) {
        return;
      }
      element.removeEventListener('mousedown', handleDown);
    };
  }, []);

  const handleDown = (e: MouseEvent) => {
    if (!ref.current) {
      return;
    }
    if (e.altKey) {
      return;
    }
    if (ref.current.scrollWidth <= ref.current.clientWidth) {
      return;
    }
    ref.current.style.cursor = 'grabbing';
    ref.current.style.userSelect = 'none';
    dragging = true;
    clickX = (e as MouseEvent).clientX;
    document.addEventListener('mousemove', handleMove);
    document.addEventListener('mouseup', handleUp);
    document.addEventListener('mouseleave', handleUp);
  };

  const handleUp = (e: MouseEvent | TouchEvent) => {
    if (!ref.current) {
      return;
    }
    ref.current.style.cursor = '';
    ref.current.style.userSelect = '';

    e.preventDefault();
    dragging = false;
    clickX = 0;
    lastLeft = ref.current.scrollLeft;
    document.removeEventListener('mousemove', handleMove);
    document.removeEventListener('mouseup', handleUp);
    document.removeEventListener('mouseleave', handleUp);
  };

  const handleMove = (e: MouseEvent | TouchEvent) => {
    if (!ref.current) {
      return;
    }
    if (dragging) {
      const scrollable = ref.current;
      if (!scrollable) {
        return;
      }
      const x =
        (e as MouseEvent).clientX ||
        ((e as TouchEvent).touches &&
          (e as TouchEvent).touches[0] &&
          (e as TouchEvent).touches[0].clientX);
      const moveDelta = x - clickX;
      if (!lastLeft) {
        lastLeft = ref.current.scrollLeft;
      }
      const targetLeft = lastLeft - moveDelta;
      scrollable.scrollLeft = targetLeft;
    }
  };

  return handleDown;
};

export const useDetectMobileOverflow = (ref: React.RefObject<HTMLElement>) => {
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    if (getScrollbarWidth() === 0 && window.innerWidth < 768) {
      ref.current.style.overflowX = 'auto';
      ref.current.style.overflowY = 'auto';
    }
  }, [ref]);
};

export const useSyncRowScroll = (ref: React.RefObject<HTMLElement>) => {
  const [rows, setRows] = useState<NodeListOf<Element> | null>(null);
  const [header, setHeaders] = useState<Element | null>(null);

  const handleRowScroll = useCallback(
    (e: Event) => {
      const scrolledRow = e.currentTarget as HTMLDivElement;
      const deltaX = scrolledRow.scrollLeft;
      console.log(deltaX);
      if (rows && header) {
        rows.forEach((row) => {
          row.scrollLeft = deltaX;
        });
        header.scrollLeft = deltaX;
      }
    },
    [header, rows]
  );
  const handleHeaderScroll = (e: Event) => {};

  useEffect(() => {
    setTimeout(() => {
      if (!ref.current) {
        return;
      }
      const rows = ref.current.querySelectorAll('.row');
      const header = ref.current.querySelector('.header');
      console.log(rows);
      setRows(rows);
      setHeaders(header);
    }, 1000);
  }, [ref]);

  useEffect(() => {
    if (rows && header) {
      rows.forEach((row) => {
        row.addEventListener('scroll', handleRowScroll);
      });
      header?.addEventListener('scroll', handleHeaderScroll);
    }
    return () => {
      if (rows && header) {
        rows.forEach((row) => {
          row.removeEventListener('scroll', handleRowScroll);
        });
        header?.removeEventListener('scroll', handleHeaderScroll);
      }
    };
  }, [handleRowScroll, header, ref, rows]);
};

export const useIsMobile = () => {
  const size = useWindowSize();
  return size.width < 900;
};

export const useTabTitle = (title: string) => {
  useEffect(() => {
    const reset = setTabTitle(title);
    return () => {
      reset();
    };
  }, []);
};

export const useLocalPagination = <T extends {}>(
  entries: T[],
  initialStep: number
) => {
  const [currentStep, setPageStep] = useState(initialStep);
  const [shownEntries, setShownEntries] = useState(
    entries.slice(0, initialStep)
  );

  useUpdateEffect(() => {
    setShownEntries(entries.slice(0, currentStep));
  }, [entries, currentStep]);

  return { shownEntries, setPageStep, currentStep };
};

export const useUpdateRef = <T extends any>(state: T) => {
  const refedState = useRef(state);
  useLayoutEffect(() => {
    refedState.current = state;
  }, [state]);
  return refedState;
};

export const useFixator = (
  containerRef: RefObject<HTMLDivElement | null | undefined>,
  fixeeRef: RefObject<HTMLDivElement | null | undefined>,
  options: {
    top: number;
    topBuffer: number;
    ieOnly: boolean;
  } = { top: 0, topBuffer: 0, ieOnly: true }
) => {
  const isMobile = useIsMobile();
  const isFixed = useRef(false);
  const onScroll = useCallback(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }
    const containerOffsets = getOffset(container);
    const containerTop =
      containerOffsets.top - window.pageYOffset + options.topBuffer;
    if (containerTop < 0) {
      fix();
    } else {
      unfix();
    }
  }, [options]);

  const fix = () => {
    if (isFixed.current) {
      return;
    }
    const container = containerRef.current;
    const fixee = fixeeRef.current;
    if (!fixee || !container) {
      return;
    }

    container.style.height = fixee.getBoundingClientRect().height + 'px';
    fixee.style.position = 'fixed';
    fixee.style.top = options.top + 'px';
    isFixed.current = true;
  };
  const unfix = () => {
    if (!isFixed.current) {
      return;
    }
    const container = containerRef.current;
    const fixee = fixeeRef.current;
    if (!fixee || !container) {
      return;
    }
    container.style.height = '';
    fixee.style.top = '';
    fixee.style.position = '';
    isFixed.current = false;
  };

  useEffect(() => {
    if ((options.ieOnly && !detectIE()) || isMobile) {
      // better use sticky
      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    }
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [isMobile, onScroll, options]);
};
