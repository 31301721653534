import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { DATE_FORMAT_SHORTER } from '../../../config';
import { ExchangeFile } from '../../../server-api/model';
import { downloadsContext } from '../../../state/downloadsState';
import { GenericSorter } from '../../../state/genericSorter';
import { toastSubject } from '../../../state/rxjs';
import { useDetectMobileOverflow, useKeys } from '../../../utils/hooks';
import { truncate } from '../../../utils/truncate';
import { Button } from '../../common/button/Button';
import { Checkbox } from '../../common/checkbox/Checkbox';
import { InputDate } from '../../common/input/InputDate';
import { CustomScrollbars } from '../../common/scrollbars/Scrollbars';
import { Spinner } from '../../common/spinner/Spinner';
import { SortHeaderCell } from '../../common/table/SortHeaderCell';
import { ModalProps } from '../Modals';
import './DownloadFiles.css';

export const DownloadFiles = observer(
  ({ showModal, closeModal }: ModalProps) => {
    const downloadsState = useContext(downloadsContext);
    const ref = useRef<HTMLDivElement>(null);

    const [sorter, setSorter] = useState(
      new GenericSorter(downloadsState.files, 'downloadFileDate', false)
    );
    const [selectedFile, selectFile] = useState<ExchangeFile | null>(null);

    useEffect(() => {
      const newSorter = new GenericSorter(
        downloadsState.files,
        sorter.sortBy,
        sorter.sortAscending
      );
      setSorter(newSorter);
      selectFile(null);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [downloadsState.files]);

    if (!downloadsState.initialized && showModal) {
      downloadsState.initialize();
    }

    const onSelect = (file: ExchangeFile) => {
      selectFile(file);
    };

    const downloadFile = () => {
      if (selectedFile) {
        downloadsState.downloadFile(selectedFile);
      } else {
        toastSubject.next('Select a file first');
      }
    };

    useDetectMobileOverflow(ref);

    if (!showModal) {
      return null;
    }

    if (downloadsState.gettingTypes) {
      return null;
    }

    return (
      <>
        <div className='flex-group1'>
          <div className='left-side'>
            <div className='filetypes-cont'>
              <CustomScrollbars
                style={{ height: '334px' }}
                scrollbarStyle={{ right: '-16px', marginTop: '-2px',height:'calc(100% + 4px)' }}
                shadow={true}
                disable={window.innerWidth < 768}
              >
                <div className='filetypes-wrapper'>
                  <h3>File type</h3>
                  <div className='filetypes'>
                    <button
                      className='btn-link'
                      onClick={downloadsState.clearFilters}
                    >
                      Clear filter
                    </button>
                    {downloadsState.fileTypes.map(({ id, name }, index) => {
                      return (
                        <Checkbox
                          inverse={true}
                          checked={downloadsState.selectedFileTypes.has(id)}
                          onChange={(e) =>
                            downloadsState.toggleCategory(
                              id,
                              e.currentTarget.checked
                            )
                          }
                          label={
                            name +
                            ' (' +
                            (downloadsState.fileTypeAmounts.get(id) || 0) +
                            ')'
                          }
                          key={'category-' + index}
                        />
                      );
                    })}
                  </div>
                </div>
              </CustomScrollbars>
            </div>
            <div className='dates-container'>
              <div className='dates-wrapper'>
                <h3>Dates</h3>
                <div className='dates'>
                  <InputDate
                    onChange={downloadsState.changeDateFrom}
                    value={downloadsState.dateFrom}
                    label='From'
                    name='dateFrom'
                    // to={downloadsState.dateTo || undefined}
                  />
                  <InputDate
                    onChange={downloadsState.changeDateTo}
                    value={downloadsState.dateTo}
                    label='To'
                    name='dateTo'
                    // from={downloadsState.dateFrom || undefined}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='right-side' ref={ref}>
            <div role='table' aria-label='Files' className='table'>
              <div role='rowgroup'>
                <div role='row' className='thead'>
                  <SortHeaderCell
                    className='file-cell-date'
                    sorter={sorter}
                    type='downloadFileDate'
                    text='Date'
                  />
                  <SortHeaderCell
                    className='file-cell-type'
                    sorter={sorter}
                    type='downloadFileType'
                    text='Type'
                  />
                  <SortHeaderCell
                    className='file-cell-name'
                    sorter={sorter}
                    type='downloadFileName'
                    text='Filename'
                  />
                  <SortHeaderCell
                    className='file-cell-downloaded'
                    sorter={sorter}
                    type='downloadFileDownloaded'
                    text='Downloaded'
                  />
                  <SortHeaderCell
                    className='file-cell-user'
                    sorter={sorter}
                    type='downloadFileUser'
                    text='User'
                  />
                </div>
              </div>
              <div role='rowgroup' className='tbody'>
                <CustomScrollbars
                  style={{}}
                  scrollbarStyle={{ right: '-16px', marginTop: '-4px' }}
                  disable={window.innerWidth < 768}
                >
                  {sorter.sortedEntries.map((file, index) => {
                    return (
                      <FileRow
                        key={'file-' + index}
                        onSelect={onSelect}
                        file={file}
                        selected={file === selectedFile ? true : false}
                      />
                    );
                  })}
                </CustomScrollbars>
              </div>
            </div>
            {downloadsState.gettingExchangeFiles ? (
              <Spinner centered={true} />
            ) : null}
          </div>
        </div>
        <div className='modal-actions'>
          <Button onClick={closeModal} className='btn btn-gray'>
            Cancel
          </Button>
          <Button
            style={{ width: '220px' }}
            onClick={downloadFile}
            mode='success'
            loading={downloadsState.downloadingFile}
          >
            Download file
          </Button>
        </div>
      </>
    );
  }
);

interface FileRowProps {
  file: ExchangeFile;
  onSelect: (file: ExchangeFile) => void;
  selected: boolean;
}

const FileRow: FC<FileRowProps> = ({ file, onSelect, selected }) => {
  const downloadsState = useContext(downloadsContext);

  return (
    <div
      role='row'
      className={'tr ' + (selected ? 'selected' : '')}
      onClick={() => onSelect(file)}
    >
      <div role='cell' className='td file-cell-date'>
        {moment(file.uploadDate).format(DATE_FORMAT_SHORTER)}
      </div>
      <div role='cell' className='td file-cell-type' title={file.clientPortalFileType.name}>
        {file.clientPortalFileType.name}
      </div>
      <div role='cell' className='td file-cell-name'>
        <button
          className='btn-linkified'
          onClick={() => downloadsState.downloadFile(file)}
        >
          {truncate(file.fileDescriptor.name, 55)}
        </button>
      </div>
      <div role='cell' className='td file-cell-downloaded'>
        {file.downloadDate
          ? moment(file.downloadDate).format(DATE_FORMAT_SHORTER)
          : null}
      </div>
      <div role='cell' className='td file-cell-user' title={file.downloadedBy}>
        {file.downloadedBy}
      </div>
    </div>
  );
};
