import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SEARCH } from '../../../routes';
import { appContext } from '../../../state/appState';
import { miscContext } from '../../../state/miscState';
import { searchContext } from '../../../state/search/searchState';
import { formatNumber, formatNumberInt } from '../../../utils/formatNumber';
import { Button } from '../../common/button/Button';
import './Summary.css';

export enum FILTER {
  ARRANGEMENT = 'arrangement',
  HOLD = 'hold',
  CGA = 'cga',
}

export const Summary = observer(() => {
  const appState = useContext(appContext);
  const miscState = useContext(miscContext);
  const searchState = useContext(searchContext);

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    setInitialized(true);
    appState.resetSummary();
    miscState.resetCheckedBookmarks();
  }, [appState, miscState]);

  if (!appState.summaryLoaded && !appState.loadingSummary) {
    appState.getSummary();
  }

  if (!appState.summaryLoaded || !initialized) {
    return (
      <div className="Summary placeholder">
        <div className="big">
          <div className="summary-card">
            <p className="card-header">
              Open cases with {window.COMPANY_LABEL}
            </p>
            <div className="card-text">
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
            <div />
          </div>
        </div>
        <div className="small">
          <div className="summary-card">
            <p className="card-header">On hold</p>
            <div className="card-text">
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
            <Button className="btn btn-success">&nbsp;</Button>
          </div>
          <div className="summary-card">
            <p className="card-header">On arrangement</p>
            <div className="card-text">
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
            <Button className="btn btn-success">&nbsp;</Button>
          </div>
          <div className="summary-card">
            <p className="card-header">Controlled goods</p>
            <div className="card-text">
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
            <Button className="btn btn-success">&nbsp;</Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="Summary">
      <div className="big">
        <div className="summary-card">
          <p className="card-header">Open cases with {window.COMPANY_LABEL}</p>
          <div className="card-text">
            <p>{formatNumberInt(appState.summary.get('Live').count)}</p>
            <p>
              (£
              {formatNumber(appState.summary.get('Live').sum)})
            </p>
          </div>
          <div />
        </div>
      </div>
      <div className="small">
        <div className="summary-card">
          <p className="card-header">On hold</p>
          <div className="card-text">
            <p>{formatNumberInt(appState.summary.get('On Hold').count)}</p>
            <p>(£{formatNumber(appState.summary.get('On Hold').sum)})</p>
          </div>
          <Link
            className="btn btn-success"
            onClick={() => searchState.toggleCaseView(true)}
            to={SEARCH + '?onHold=yes'}
          >
            <span>View cases</span>
            <span className="compact">View</span>
          </Link>
        </div>

        <div className="summary-card">
          <p className="card-header">On arrangement</p>
          <div className="card-text">
            <p>{formatNumberInt(appState.summary.get('Arrangement').count)}</p>
            <p>(£{formatNumber(appState.summary.get('Arrangement').sum)})</p>
          </div>
          <Link
            className="btn btn-success"
            onClick={() => searchState.toggleCaseView(true)}
            to={SEARCH + '?status=A'}
          >
            <span>View cases</span>
            <span className="compact">View</span>
          </Link>
        </div>

        <div className="summary-card">
          <p className="card-header">Controlled goods</p>
          <div className="card-text">
            <p>{formatNumberInt(appState.summary.get('Vulnerable').count)}</p>
            <p>(£{formatNumber(appState.summary.get('Vulnerable').sum)})</p>
          </div>
          <Link
            className="btn btn-success"
            onClick={() => searchState.toggleCaseView(true)}
            to={SEARCH + '?cga=true'}
          >
            <span>View cases</span>
            <span className="compact">View</span>
          </Link>
        </div>
      </div>
    </div>
  );
});
