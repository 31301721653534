import { Button } from 'components/common/button/Button';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext } from 'react';
import { DATE_FORMAT_SHORTER } from '../../../config';
import { USER_ROLE } from '../../../server-api/model';
import { debtorContext } from '../../../state/debtorState';
import { modalContext } from '../../../state/modalState';
import { profileContext } from '../../../state/profileState';
import { capitalize } from '../../../utils/capitalize';
import { useTabTitle } from '../../../utils/hooks';
import { splitByNewline } from '../../../utils/splitByNewline';
import { Actions } from '../../common/actions';
import { DebtorArrangement } from './arrangement/DebtorArrangement';
import { CourtAppointments } from './court-appointments/CourtAppointments';
import './InfoDebtor.css';
import { AdditionalParties, Names } from './InfoDebtorParts';
import { InfoDebtorPlaceholder } from './InfoDebtorPlaceholder';
import { DebtorPayments } from './payments/DebtorPayments';
import { DebtorStatement } from './statement/DebtorStatement';

export const InfoDebtor = observer(() => {
  useTabTitle(`Customer Details | ${window.COMPANY} Client Portal`);
  const debtorState = useContext(debtorContext);
  const debtorDetails = debtorState.debtorDetails;
  const actions = new Actions(useContext(modalContext)).getDebtorActions();
  const profileState = useContext(profileContext);
  const modalState = useContext(modalContext);

  if (!debtorDetails || debtorState.loadingDebtorDetails) {
    return <InfoDebtorPlaceholder />;
  }

  const handlePrevAddress = () => {
    const toRender: (JSX.Element[] | JSX.Element)[] = [];
    let renderedAddress = '';
    const addresses = debtorDetails.debtorDto.contractor.addresses;

    if (addresses) {
      addresses.forEach((address) => {
        if (!debtorDetails.debtorDto.mainAddress && !renderedAddress.length) {
          renderedAddress = address.splitAddress;

          toRender.push(
            address.splitAddress.split('\n').map((line, index) => {
              return (
                <React.Fragment key={line}>
                  <span>{line}</span>
                  <br />
                </React.Fragment>
              );
            })
          );
          return;
        }

        if (!debtorDetails.debtorDto.mainAddress) {
          return;
        }

        if (
          address.splitAddress !==
            debtorDetails.debtorDto.mainAddress.splitAddress &&
          !renderedAddress.length
        ) {
          renderedAddress = address.splitAddress;
          toRender.push(
            address.splitAddress.split('\n').map((line, index) => {
              return (
                <React.Fragment key={line}>
                  <span>{line}</span>
                  <br />
                </React.Fragment>
              );
            })
          );
        }
      });

      if (addresses.length > 1) {
        let gotOtherAddresses = false;
        addresses.forEach((address) => {
          if (!debtorDetails.debtorDto.mainAddress) {
            return;
          }

          if (
            address.splitAddress !==
              debtorDetails.debtorDto.mainAddress.splitAddress &&
            address.splitAddress !== renderedAddress
          ) {
            gotOtherAddresses = true;
          }
        });
        if (gotOtherAddresses) {
          toRender.push(
            <button
              onClick={() => modalState.toggleModal('customerAddresses', true)}
              className='btn btn-primary'
            >
              View more
            </button>
          );
        }
      }
    }

    return toRender;
  };

  const getBenefits = (benefits?: string[]) => {
    if (!benefits) {
      return null;
    }
    return benefits.map((benefit) => {
      return (
        <React.Fragment key={benefit}>
          <span>{benefit}</span>
          <br />
        </React.Fragment>
      );
    });
  };

  const emails: string[] = [];
  const others: Array<{ value: string; type: string }> = [];
  let niNumber = '';
  debtorDetails.debtorDto.contractor.contactDetails.forEach((detail) => {
    if (detail.type === 'NI_NUMBER') {
      niNumber = detail.value;
    } else if (detail.type === 'EMAIL') {
      emails.push(detail.value);
    } else {
      others.push({ ...detail, type: detail.type || 'UNKNOWN' });
    }
  });

  

  return (
    <div className='InfoDebtor'>
      <div className={'action-buttons '}>
        {actions.map((action, index) => {
          return (
            <Button
              key={'button-' + index + action.name}
              disabled={profileState.portalRole === USER_ROLE.VIEW_ONLY &&
                action.name.indexOf('Message') === -1}
              mode='success'
              onClick={() => action.action()}
            >
              {action.name}
            </Button>
          );
        })}
      </div>

      <div className='flex-group1'>
        <div className='contact-details'>
          <h2 className='contact-details-header'>Contact details</h2>
          <div className='contact-details-flex-group'>
            <table className='table-left'>
              <tbody>
                <Names debtor={debtorDetails.debtorDto} />
                <tr>
                  <th>Other liable</th>
                  <td className='multiline-container'>
                    {splitByNewline(debtorDetails.otherLiable)}
                  </td>
                </tr>
                {others.length ? (
                  <>
                    <tr className='spacer-row'>
                      <td>&nbsp;</td>
                    </tr>
                    {others.map((detail, index) => {
                      return (
                        <tr key={'detail-' + detail.value}>
                          <th>
                            {OTHER_DETAILS[detail.type] ||
                              detail.type.split('_').join(' ').toLowerCase()}
                          </th>
                          <td>{detail.value}</td>
                        </tr>
                      );
                    })}
                  </>
                ) : null}
                {emails.length ? (
                  <>
                    <tr className='spacer-row'>
                      <td>&nbsp;</td>
                    </tr>
                    {emails.map((email, index) => {
                      return (
                        <tr key={'email-' + email}>
                          <th>Email</th>
                          <td>{email}</td>
                        </tr>
                      );
                    })}
                  </>
                ) : null}
                {debtorDetails.debtorDto.alsoKnownAs ? (
                  <>
                    <tr className='spacer-row'>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <th>AKA</th>
                      <td className='multiline-container'>
                        {splitByNewline(debtorDetails.debtorDto.alsoKnownAs)}
                      </td>
                    </tr>
                  </>
                ) : null}
                <AdditionalParties
                  additionalParties={debtorDetails.additionalParties}
                />
                <tr className='spacer-row'>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
            <table className='table-right'>
              <tbody>
                <tr>
                  <th>Current address</th>
                  <td className='multiline-container'>
                    {debtorDetails.debtorDto.mainAddress
                      ? splitByNewline(
                          debtorDetails.debtorDto.mainAddress.splitAddress
                        )
                      : ''}
                  </td>
                </tr>
                <tr className='spacer-row'>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <th>Other addresses</th>
                  <td className='multiline-container'>{handlePrevAddress()}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className='employment'>
          <h2 className='employment-header'>Employment</h2>
          <table className='table-employment'>
            <tbody>
              <tr>
                <th>Employer</th>
                <td>
                  {debtorDetails.debtorDto.meansForm &&
                    debtorDetails.debtorDto.meansForm.employmentName}
                </td>
              </tr>
              <tr>
                <th>Telephone</th>
                <td>
                  {debtorDetails.debtorDto.meansForm &&
                    debtorDetails.debtorDto.meansForm.employmentPhoneNumber}
                </td>
              </tr>
              <tr>
                <th>Email</th>
                <td>
                  {debtorDetails.debtorDto.meansForm &&
                    debtorDetails.debtorDto.meansForm.emailAddress}
                </td>
              </tr>
              <tr className='spacer-row'>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>
                  {debtorDetails.debtorDto.meansForm &&
                    debtorDetails.debtorDto.meansForm.employmentAddress}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className='other-details'>
          <h2 className='other-details-header'>Other details</h2>
          <table className='table-other'>
            <tbody>
              <tr>
                <th>Receiving benefits</th>
                <td>{getBenefits(debtorDetails.debtorDto.benefits)}</td>
              </tr>
              <tr className='spacer-row'>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <th>Risk assessment</th>
                <td>
                  {capitalize(
                    debtorDetails.debtorDto.actualRiskAssessment?.status || ''
                  )}
                </td>
              </tr>
              <tr>
                <th>Date</th>
                <td>
                  {debtorDetails.debtorDto.actualRiskAssessment?.assessmentDate
                    ? moment(
                        debtorDetails.debtorDto.actualRiskAssessment
                          .assessmentDate
                      ).format(DATE_FORMAT_SHORTER)
                    : null}
                </td>
              </tr>
              <tr>
                <th>Rating</th>
                <td>
                  {capitalize(
                    debtorDetails.debtorDto.actualRiskAssessment?.riskRating ||
                      ''
                  )}
                </td>
              </tr>
              <tr>
                <th>Comments</th>
                <td>
                  {debtorDetails.debtorDto.actualRiskAssessment?.comment || ''}
                </td>
              </tr>
              <tr className='spacer-row'>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <th>Date of birth</th>
                <td>
                  {debtorDetails.debtorDto.contractor.birthDate
                    ? moment(
                        debtorDetails.debtorDto.contractor.birthDate
                      ).format(DATE_FORMAT_SHORTER)
                    : ''}
                </td>
              </tr>
              <tr>
                <th>{OTHER_DETAILS.NI_NUMBER}</th>
                <td>{niNumber}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <CourtAppointments />
      <DebtorArrangement />
      {debtorState.casesHavePayments ? (
        <div className='flex-group3'>
          <DebtorStatement />
          <DebtorPayments />
        </div>
      ) : null}
    </div>
  );
});

export const OTHER_DETAILS = {
  NI_NUMBER: 'NI Number',
  DRIVING_LICENSE: 'Driving License',
  MOBILE_PHONE: 'Mobile Phone',
  OFFICE_PHONE: 'Office Phone',
  HOME_PHONE: 'Home Phone',
  EMAIL: 'Email',
  FAX: 'Fax',
  UNKNOWN: 'Unknown',
};
