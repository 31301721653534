import React, { useContext } from 'react';
import { usersContext, USER_ACTION } from '../../state/usersState';
import { action } from 'mobx';
import { toastSubject } from '../../state/rxjs';
import { USER_ROLE } from '../../server-api/model';
import { Button } from '../common/button/Button';
import { Checkbox } from '../common/checkbox/Checkbox';
import { modalContext } from '../../state/modalState';
import { promptsContext } from '../../state/promptState';
import { BlockFlex } from '../common/blocks/Blocks';

export const ManageUsersButtons = () => {
  const usersState = useContext(usersContext);
  const modalState = useContext(modalContext);
  const promptState = useContext(promptsContext);

  const handleClickViewOnly = action(() => {
    const actionCheck = usersState.checkActions(USER_ACTION.ROLE);
    if (actionCheck.error) {
      toastSubject.next(actionCheck.error);
      return;
    }
    usersState.targetRole = USER_ROLE.VIEW_ONLY;
    promptState.togglePrompt('userRole', true);
  });
  const handleClickUpdate = action(() => {
    const actionCheck = usersState.checkActions(USER_ACTION.ROLE);
    if (actionCheck.error) {
      toastSubject.next(actionCheck.error);
      return;
    }
    usersState.targetRole = USER_ROLE.UPDATE;
    promptState.togglePrompt('userRole', true);
  });
  const handleClickManager = action(() => {
    const actionCheck = usersState.checkActions(USER_ACTION.ROLE);
    if (actionCheck.error) {
      toastSubject.next(actionCheck.error);
      return;
    }
    usersState.targetRole = USER_ROLE.MANAGER;
    promptState.togglePrompt('userRole', true);
  });
  const handleClickActive = action(() => {
    const actionCheck = usersState.checkActions(USER_ACTION.STATUS);
    if (actionCheck.error) {
      toastSubject.next(actionCheck.error);
      return;
    }
    usersState.targetStatus = true;
    promptState.togglePrompt('userStatus', true);
  });
  const handleClickInactive = action(() => {
    const actionCheck = usersState.checkActions(USER_ACTION.STATUS);
    if (actionCheck.error) {
      toastSubject.next(actionCheck.error);
      return;
    }
    usersState.targetStatus = false;
    promptState.togglePrompt('userStatus', true);
  });
  const handleClickEnableTwoFactor = action(() => {
    const actionCheck = usersState.checkActions(USER_ACTION.TWO_FACTOR);
    if (actionCheck.error) {
      toastSubject.next(actionCheck.error);
      return;
    }
    usersState.targetTwoFactor = true;
    promptState.togglePrompt('userTwoFactor', true);
  });
  const handleClickDisableTwoFactor = action(() => {
    const actionCheck = usersState.checkActions(USER_ACTION.TWO_FACTOR);
    if (actionCheck.error) {
      toastSubject.next(actionCheck.error);
      return;
    }
    usersState.targetTwoFactor = false;
    promptState.togglePrompt('userTwoFactor', true);
  });

  const doNewUser = () => {
    usersState.prepareNewUser().then(() => {
      modalState.toggleModal('userDetails', true);
    });
  };

  return (
    <BlockFlex offset="small" align="end" justify="start" direction="row">
      <BlockFlex className="users-action-group">
        <div className="action-buttons">
          <Button
            onClick={handleClickViewOnly}
            className='btn-user-action'
            disabled={!usersState.checkActions(USER_ACTION.ROLE).success}
            mode='success'
          >
            View only
          </Button>
          <Button
            onClick={handleClickUpdate}
            className='btn-user-action'
            disabled={!usersState.checkActions(USER_ACTION.ROLE).success}
            mode='success'
          >
            Update
          </Button>
          <Button
            onClick={handleClickManager}
            className='btn-user-action'
            disabled={!usersState.checkActions(USER_ACTION.ROLE).success}
            mode='success'
          >
            Manager
          </Button>
        </div>
        <h3>User roles</h3>
      </BlockFlex>
      <BlockFlex className="users-action-group">
        <div className="action-buttons">
          <Button
            onClick={handleClickActive}
            className='btn-user-action'
            disabled={!usersState.checkActions(USER_ACTION.STATUS).success}
            mode='success'
          >
            Active
          </Button>
          <Button
            onClick={handleClickInactive}
            className='btn-user-action'
            disabled={!usersState.checkActions(USER_ACTION.STATUS).success}
            mode='success'
          >
            Inactive
          </Button>
        </div>
        <h3>User status</h3>
      </BlockFlex>
      <BlockFlex className="users-action-group">
        <div className="action-buttons">
          <Button
            onClick={handleClickEnableTwoFactor}
            className='btn-user-action'
            disabled={!usersState.checkActions(USER_ACTION.TWO_FACTOR).success}
            mode='success'
          >
            Enable
          </Button>
          <Button
            onClick={handleClickDisableTwoFactor}
            className='btn-user-action'
            disabled={!usersState.checkActions(USER_ACTION.TWO_FACTOR).success}
            mode='success'
          >
            Disable
          </Button>
        </div>
        <h3>Two factor authentication</h3>
      </BlockFlex>
      <div className="users-action-group create-user-group">
        <BlockFlex>
          <div className="action-buttons">
            <Button onClick={doNewUser} className="btn">
              Create new user
            </Button>
          </div>

          <div className="select-users-checkbox">
            <Checkbox
              checked={usersState.selectedAllUsers}
              onChange={usersState.toggleSelectAllUsers}
              label="Select all users"
            />
          </div>
        </BlockFlex>
      </div>
    </BlockFlex>
  );
};
