import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { filter } from 'rxjs/operators';
import './ValidationError.css';
import './ValidationErrorTheme.css';

import {
  inputValidatedSubject,
  runValidationSubject,
  validateInputSubject,
  validator,
} from './validator';

interface ValidationProps {
  inputId: string;
  formId: string;
  active?: boolean;
  forceCircle?: boolean;
  validateFunc?: () => undefined | string[] | Promise<undefined | string[]>;
  dirty?: boolean;
  standalone?: boolean;
  value: any;
}

export const ValidationError: FC<ValidationProps> = ({
  inputId,
  formId,
  standalone,
  active: outsideActive,
  value,
  validateFunc,
  dirty: outsideDirty,
  forceCircle,
}) => {
  const [error, setError] = useState<string | null>();
  const [isValid, setValid] = useState(false);
  const [isDirty, setDirty] = useState(outsideDirty || false);
  const [isValidating, setValidating] = useState(false);
  const lastValue = useRef(value);

  const ids = useRef({ formId, inputId });

  const validateSelf = useCallback(
    async (showErrors: boolean) => {
      if (!validateFunc) {
        return;
      }
      setValidating(true);
      const newError = await validateFunc();
      setValidating(false);
      if (showErrors) {
        setTimeout(() => {
          setDirty(true);
        }, 100);
      }
      if (newError) {
        setError(newError[0]);
        setValid(false);
      } else {
        setValid(true);
      }
      inputValidatedSubject.next({
        error: newError ? true : false,
        formId,
        inputId,
      });
      return newError;
    },
    [validateFunc, formId, inputId]
  );

  const validateSelfRef = useRef(validateSelf);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (lastValue.current !== value) {
        lastValue.current = value;
        if (!isDirty) {
          setDirty(true);
        } else {
          validateSelfRef.current(true);
        }
      }
    }, 0);
    return () => {
      clearTimeout(timeout);
    };
  }, [value]);

  useEffect(() => {
    if (outsideActive) {
      setDirty(true);
    }
  }, [outsideActive]);

  useEffect(() => {
    if (outsideDirty) {
      setDirty(true);
    }
  }, [outsideDirty]);

  useEffect(() => {
    if (isDirty) {
      validateSelfRef.current(true);
    } else {
      validateSelfRef.current(false);
    }
  }, [isDirty]);

  useEffect(() => {
    validateSelfRef.current = validateSelf;
  }, [validateSelf]);

  useEffect(() => {
    const sub1 = runValidationSubject
      .pipe(filter(({ validatedFormId }) => validatedFormId === formId))
      .subscribe(async ({ showErrors, validatedFormId }) => {
        if (validateFunc) {
          validateSelfRef.current(showErrors);
        }
      });
    const sub2 = validateInputSubject
      .pipe(
        filter(
          ({ validatedFormId, validatedInputId }) =>
            validatedFormId === formId && validatedInputId === inputId
        )
      )
      .subscribe(async ({ showErrors }: { showErrors: boolean }) => {
        if (validateFunc) {
          validateSelfRef.current(showErrors);
        }
      });

    return () => {
      sub1.unsubscribe();
      sub2.unsubscribe();
    };
  }, [formId, inputId]);

  useEffect(() => {
    const savedFormId = ids.current.formId;
    const savedInputId = ids.current.inputId;
    validator.register(savedFormId, savedInputId);
    return () => {
      validator.unregister(savedFormId, savedInputId);
    };
  }, []);

  if (!validateFunc) {
    return null;
  }

  return (
    <>
      {!isDirty && !standalone ? null : (
        <div
          className={
            'validation-error ' +
            (outsideActive || isValidating ? 'validation-error--active ' : '') +
            (isValid ? 'validation-error--valid ' : '') +
            (standalone ? 'validation-error--standalone ' : '') +
            (isDirty ? 'validation-error--dirty ' : '') +
            (isValid && (!value || (typeof value === 'string' && !value.length))
              ? 'validation-error--optional-valid '
              : '')
          }
        >
          <div className="validation-error__border"></div>
          {error ? (
            <span className="validation-error__text" role='alert'>{error}</span>
          ) : null}
        </div>
      )}
    </>
  );
};
