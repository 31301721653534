import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { DEFAULT_MAX_LENGTH } from '../../../config';
import { detectIE } from '../../../utils/detectIE';
import { CommonInputProps } from './common';
import './TextField.css';
import { ValidationError } from './ValidationError';

interface InputProps extends CommonInputProps {
  value?: string | number | null;
  onChange?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  onFocusIn?: () => any;
  onFocusOut?: () => any;
  size?: number;
  maxLength?: number;
  type?: string;
  readOnly?: true;
  properties?: any;
  cols?: number;
  rows?: number;
  noResize?: true;
  lengthCounter?: boolean;
}

export const TextField: React.FC<InputProps> = observer(
  ({
    value,
    validateFunc,
    formName,
    onChange,
    onFocusIn,
    onFocusOut,
    width = 'normal',
    placeholder,
    maxLength,
    size,
    name,
    label,
    type,
    className,
    readOnly,
    properties,
    cols,
    rows = 3,
    noResize = true,
    lengthCounter,
  }) => {
    const ref = useRef<HTMLInputElement>(null);
    const [focused, setFocused] = useState(false);

    const handleChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
      if (onChange) {
        onChange(e);
      }
    };

    const checkHeight = () => {
      const textarea = ref.current;
      if (!textarea) {
        return;
      }
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    };

    const getClassnames = () => {
      let cls = 'input-' + width;
      cls += className ? ' ' + className : '';
      return cls;
    };

    useEffect(() => {
      if (detectIE()) {
        return;
      }
      checkHeight();
    }, [value]);

    return (
      <div className={'TextField ' + getClassnames()} id={name}>
        {label ? <label className="input-label" htmlFor={name + '-textarea'}>{label}</label> : null}
        <div className="text-wrapper">
          <div className={'text-container' + (focused ? ' active' : '')}>
            <textarea
              maxLength={maxLength || DEFAULT_MAX_LENGTH}
              ref={ref}
              id={name + '-textarea'}
              name={name + '-textarea'}
              placeholder={placeholder}
              size={size}
              onInput={handleChange}
              onChange={handleChange}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              value={value}
              className={noResize ? 'noresize ' : ''}
              readOnly={readOnly}
              cols={cols}
              rows={rows}
              aria-label={placeholder}
              {...properties}
            />
          </div>
          {formName ? (
            <ValidationError
              value={value}
              validateFunc={validateFunc}
              inputId={name}
              formId={formName}
              active={focused}
            />
          ) : null}
        </div>
        {lengthCounter ? (
          <div className="char-limit-counter">
            {value ? value.toString().length : 0}/{maxLength}
          </div>
        ) : null}
      </div>
    );
  }
);
