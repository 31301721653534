import { observer } from "mobx-react";
import React, { useState } from "react";
import { IconBookmark } from "../../common/icons";
import { FavList } from "./FavList";
import "./Lists.css";
import { RecentsList } from "./RecentsList";
import { SavedList } from "./SavedList";

export enum LIST_TAB {
  RECENTS = "recents",
  FAVORITES = "favs",
  SAVED = "saved",
}

export const Lists = observer(() => {
  const [selectedTab, selectTab] = useState("recents");

  return (
    <div className="Lists">
      <div className="tabs">
        <button
          className={
            "tab tab-recents" +
            (selectedTab === LIST_TAB.RECENTS ? " tab-active" : "")
          }
          onClick={() => selectTab("recents")}
        >
          Recently Viewed
        </button>
        <button
          className={
            "tab tab-saved" +
            (selectedTab === LIST_TAB.SAVED ? " tab-active" : "")
          }
          onClick={() => selectTab("saved")}
        >
          Saved Searches
        </button>
        <button
          className={
            "tab tab-favs" +
            (selectedTab === LIST_TAB.FAVORITES ? " tab-active" : "")
          }
          onClick={() => selectTab("favs")}
          aria-label="Favourites"
        >
          <IconBookmark />
        </button>
      </div>
      <div className="list-container">
        <RecentsList selectedTab={selectedTab} />
        <SavedList selectedTab={selectedTab} />
        <FavList selectedTab={selectedTab} />
      </div>
    </div>
  );
});
