import React, { CSSProperties, ReactNode } from 'react';
import { Spinner } from '../spinner/Spinner';
import './Button.css';

export type ButtonWidth =
  | 'short'
  | 'normal'
  | 'semiwide'
  | 'wide'
  | 'wider'
  | 'widest'
  | 'block';

export type ButtonProps = {
  mode?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'outline'
    | 'danger'
    | 'icon-small';
  size?: 'normal' | 'small';
  children?: ReactNode;
  disabled?: boolean;
  disabledClickable?: boolean;
  loadingInline?: boolean;
  loading?: boolean;
  width?: ButtonWidth;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const Button = ({
  mode = 'primary',
  size = 'normal',
  disabled,
  disabledClickable,
  loadingInline,
  loading,
  children,
  className = '',
  width,
  ...rest
}: ButtonProps) => {
  const computedDisabled = isDisabled({
    disabled: disabled && !disabledClickable,
    loading: loadingInline || loading,
  });

  return (
    <button
      type="button"
      className={`${composeClasses({
        mode,
        size,
        width,
        className,
        disabled,
        disabledClickable,
      })}
      `}
      disabled={computedDisabled}
      {...rest}
    >
      {loading ? <Spinner white small btnReplace /> : null}
      <span className={'btn-content' + (loading ? ' hide' : '')}>{children}</span>
      {loadingInline ? <Spinner small white /> : null}
    </button>
  );
};

function composeClasses({
  mode: type,
  size,
  width,
  className,
  disabled,
  disabledClickable,
}: Partial<ButtonProps>) {
  const classList = ['btn'];

  if (width) {
    classList.push('btn-' + width);
  }

  if (type) {
    classList.push('btn-' + type);
  }

  if (size && size !== 'normal') {
    classList.push('btn-' + size);
  }

  if (className) {
    classList.push(className);
  }
  if (disabled) {
    classList.push('btn-disabled');
  }
  if (disabledClickable) {
    classList.push('btn-disabled clickable');
  }

  return classList.join(' ');
}

function isDisabled({ disabled, loading }: Partial<ButtonProps>): boolean {
  return disabled === true || loading === true;
}
