import React from "react";
import "./ToggleWide.css";

interface Props {
  id?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  checked: boolean;
  textOn: string;
  textOff: string;
  properties?: any;
}

const clickCheckbox = (e: React.FormEvent<HTMLDivElement>) => {
  e.currentTarget.getElementsByTagName('input')[0].click();
};

export const ToggleWide = ({ id, onChange, checked, textOn, textOff, properties }: Props) => {
  return (
    <div className="switch-wide" onClick={clickCheckbox}>
      <input id={id} type="checkbox" checked={checked} onChange={onChange} onClick={(e) => e.stopPropagation()} {...properties} />
      <span className="slider round" />
      <div className="text-on-off">
        <span>{textOn}</span>
        <span>{textOff}</span>
      </div>
    </div>
  );
};
