import { Button } from 'components/common/button/Button';
import { observer } from 'mobx-react';
import React, { useCallback, useContext } from 'react';
import { reportsContext } from '../../../state/reportsState';
import { useKeys, useValidation } from '../../../utils/hooks';
import { Checkbox } from '../../common/checkbox/Checkbox';
import { InputDate } from '../../common/input/InputDate';
import { Input } from '../../common/input/Input';
import { InputSelect } from '../../common/input/InputSelect';
import { validator } from '../../common/input/validator';
import { ModalProps } from '../Modals';
import './Reports.css';
import validate from 'validate.js';
import { ValidationError } from '../../common/input/ValidationError';
import { InputTime } from '../../common/input/InputTime';

export const ReportsModal = observer(
  ({ showModal, closeModal }: ModalProps) => {
    const formName = 'ReportsModal';
    const reportsState = useContext(reportsContext);

    const submit = useCallback(() => {
      validator
        .validate(formName, true)
        .then(() => {
          reportsState
            .submitReport()
            .then(() => {
              closeModal();
            })
            .catch(() => {});
        })
        .catch(() => 0);
    }, [reportsState, formName, closeModal]);

    useKeys(['Enter'], [submit], !showModal);

    useValidation(formName);

    if (!showModal) {
      return null;
    }

    return (
      <>
        {reportsState.preparingReport ? (
          <div className="placeholder" style={{}}>
            <h3>Enter report parameters</h3>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
        ) : (
          <div className="report-content">
            <h3>Enter report parameters</h3>
            {reportsState.reportParams.map((param, index) => {
              switch (param.type) {
                case 'BOOLEAN':
                  return (
                    <InputSelect
                      formName={param.required ? formName : undefined}
                      name={param.alias}
                      label={param.name}
                      options={{ Yes: true, No: false, '\xA0': undefined }}
                      validateFunc={() =>
                        validate.single(reportsState.paramValues.get(param), {
                          presence: { allowEmpty: false },
                        })
                      }
                      onChange={(value: string) =>
                        reportsState.changeParam(param, value)
                      }
                      selectedThing={reportsState.paramValues.get(param)}
                      key={'key' + param.alias + index}
                    />
                  );
                case 'DATE':
                  if (!reportsState.paramValues.get(param)) {
                    // reportsState.changeParam(param, new Date().getTime());
                  }
                  return (
                    <InputDate
                      formName={param.required ? formName : undefined}
                      name={param.alias + '-date'}
                      label={param.name}
                      onChange={(date) => reportsState.changeParam(param, date)}
                      validateFunc={() =>
                        validate.single(
                          reportsState.paramValues.get(param) as number,
                          {
                            presence: { allowEmpty: false },
                          }
                        )
                      }
                      value={reportsState.paramValues.get(param) as number}
                      key={'key-' + param.alias + index}
                    />
                  );
                case 'DATETIME':
                  return (
                    <InputDate
                      formName={param.required ? formName : undefined}
                      name={param.alias + '-date'}
                      label={param.name}
                      onChange={(date) => reportsState.changeParam(param, date)}
                      validateFunc={() =>
                        validate.single(
                          reportsState.paramValues.get(param) as number,
                          {
                            presence: { allowEmpty: false },
                          }
                        )
                      }
                      value={reportsState.paramValues.get(param) as number}
                      key={'key-' + param.alias + index}
                      time
                    />
                  );
                case 'TIME':
                  return (
                    <InputTime
                      formName={param.required ? formName : undefined}
                      name={param.alias + '-date'}
                      label={param.name}
                      onChange={(date) => reportsState.changeParam(param, date)}
                      validateFunc={() =>
                        validate.single(
                          reportsState.paramValues.get(param) as number,
                          {
                            presence: { allowEmpty: false },
                          }
                        )
                      }
                      value={reportsState.paramValues.get(param) as number}
                      key={'key-' + param.alias + index}
                    />
                  );
                case 'NUMERIC':
                  return (
                    <Input
                      name={param.alias}
                      options={{
                        numeralPositiveOnly: true,
                        numeral: true,
                        numeralThousandsGroupStyle: 'none',
                        numeralDecimalScale: 5,
                      }}
                      formName={param.required ? formName : undefined}
                      validateFunc={() =>
                        validate.single(
                          reportsState.paramValues.get(param) as number,
                          { presence: { allowEmpty: false } }
                        )
                      }
                      label={param.name}
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        reportsState.changeParam(
                          param,
                          parseFloat(e.currentTarget.value)
                        )
                      }
                      value={reportsState.paramValues.get(param) as string}
                      key={'key' + param.alias + index}
                    />
                  );
                default:
                  switch (param.alias.toLowerCase()) {
                    case 'contract':
                      return (
                        <div
                          className="checkboxes"
                          key={'key' + param.alias + index}
                        >
                          {Object.entries(reportsState.contracts).map(
                            ([key, value]) => {
                              return (
                                <div
                                  style={{ display: 'block' }}
                                  key={'key' + value + index}
                                >
                                  <Checkbox
                                    label={key}
                                    checked={reportsState.selectedContracts.has(
                                      value
                                    )}
                                    id={'report-checkbox-' + value}
                                    onChange={(e) => {
                                      reportsState.setContract(
                                        value,
                                        e.currentTarget.checked
                                      );
                                    }}
                                    inverse={true}
                                  />
                                </div>
                              );
                            }
                          )}
                          <ValidationError
                            formId={formName}
                            value={reportsState.selectedContracts}
                            inputId={'contract-checkboxes'}
                            validateFunc={() =>
                              reportsState.selectedContracts.size
                                ? undefined
                                : ['At least one contract is required']
                            }
                            standalone
                          />
                        </div>
                      );
                    case 'client':
                      if (!reportsState.paramValues.get(param)) {
                        reportsState.changeParam(
                          param,
                          Object.entries(reportsState.clients)[0][1]
                        );
                      }
                      return (
                        <InputSelect
                          formName={param.required ? formName : undefined}
                          name={param.alias}
                          label={param.name}
                          options={reportsState.clients}
                          validateFunc={() =>
                            validate.single(
                              reportsState.paramValues.get(param),
                              { presence: { allowEmpty: false } }
                            )
                          }
                          onChange={(value: string) =>
                            reportsState.changeParam(param, value)
                          }
                          selectedThing={reportsState.paramValues.get(param)}
                          key={'key' + param.alias + index}
                        />
                      );
                    case 'scheme':
                      if (!reportsState.paramValues.get(param)) {
                        reportsState.changeParam(
                          param,
                          Object.entries(reportsState.contracts)[0][1]
                        );
                      }
                      return (
                        <InputSelect
                          formName={param.required ? formName : undefined}
                          name={param.alias}
                          label={param.name}
                          validateFunc={() =>
                            validate.single(
                              reportsState.paramValues.get(param),
                              { presence: { allowEmpty: false } }
                            )
                          }
                          options={reportsState.contracts}
                          onChange={(value: string) =>
                            reportsState.changeParam(param, value)
                          }
                          selectedThing={reportsState.paramValues.get(param)}
                          key={'key' + param.alias + index}
                        />
                      );
                    default:
                      return (
                        <Input
                          name={param.alias}
                          formName={param.required ? formName : undefined}
                          label={param.name}
                          validateFunc={() =>
                            validate.single(
                              reportsState.paramValues.get(param),
                              { presence: { allowEmpty: false } }
                            )
                          }
                          onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            reportsState.changeParam(
                              param,
                              e.currentTarget.value
                            )
                          }
                          value={
                            (reportsState.paramValues.get(param) as string) ||
                            ''
                          }
                          key={'key' + param.alias + index}
                        />
                      );
                  }
              }
            })}
          </div>
        )}

        <div className="modal-actions">
          <Button onClick={closeModal} className="btn btn-gray">
            Cancel
          </Button>
          <Button
            onClick={submit}
            className="btn btn-success"
            loading={reportsState.runningReport}
          >
            Run report
          </Button>
        </div>
      </>
    );
  }
);
