import { Button } from 'components/common/button/Button';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DATE_FORMAT_SHORTER } from '../../../config';
import { filesContext } from '../../../state/filesState';
import { GenericSorter } from '../../../state/genericSorter';
import { CustomScrollbars } from '../../common/scrollbars/Scrollbars';
import { Spinner } from '../../common/spinner/Spinner';
import { SortHeaderCell } from '../../common/table/SortHeaderCell';
import { useOnKeySelect } from 'utils/hooks';

export const Letters = observer(() => {
  const filesState = useContext(filesContext);
  const [sorter, updateSorter] = useState(
    new GenericSorter(filesState.letters, 'letterDate', false)
  );
  const [tableFocused, setTableFocused] = useState(false);
  const tableRef = useRef<HTMLDivElement>(null);

  useOnKeySelect(tableFocused, tableRef, 'div.letter-row');

  const viewLetter = () => {
    filesState.printLetter();
  };

  const handleBlur = (e: React.FocusEvent) => {
    if (!tableRef.current?.contains(e.relatedTarget as any)) {
      setTableFocused(false);
    }
  }

  const handleFocus = (e: React.FocusEvent) => {
    if (tableRef.current?.contains(e.target as any)) {
      setTableFocused(true);
    }
  }

  useEffect(() => {
    const newSorter = new GenericSorter(
      filesState.letters,
      'letterDate',
      false
    );
    updateSorter(newSorter);
  }, [filesState.letters]);

  return (
    <div className='letters'>
      <h2 id='lettersLabel'>Outgoing letters</h2>

      <div className='letters-table-wrapper'>
        <div
          role='table'
          aria-labelledby='lettersLabel'
          className='table letters-table'
          ref={tableRef}
          tabIndex={0}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <div role='rowgroup'>
            <div role='row' className='thead'>
              <SortHeaderCell
                sorter={sorter}
                type='letterDate'
                text='Date'
                className='letter-cell-date'
              />
              <SortHeaderCell
                  sorter={sorter}
                  type='channelUsed'
                  text='Channel'
                  className='letter-cell-channel'
              />
              <SortHeaderCell
                sorter={sorter}
                type='letterType'
                text='Type'
                className='letter-cell-type'
              />
            </div>
          </div>
          <div role='rowgroup' className='tbody'>
            <CustomScrollbars
              style={{
                maxHeight: 730 + 'px',
              }}
              translateContentSize
              scrollbarStyle={{
                right: '-15.5px',
                marginTop: '-4px',
                height: 'calc(100% + 4px)',
              }}
            >
              {sorter.sortedEntries.map((letter, index) => {
                return (
                  <div
                    role='row'
                    className={
                      'tr letter-row ' +
                      (letter === filesState.selectedLetter ? 'selected' : '')
                    }
                    key={letter.id + index}
                    onClick={() => filesState.selectLetter(letter)}
                    aria-selected={letter === filesState.selectedLetter}
                    tabIndex={-1}
                  >
                    <div role='cell' className='td letter-cell-date'>
                      {letter.sentDate
                        ? moment(letter.sentDate).format(DATE_FORMAT_SHORTER)
                        : 'Unknown'}
                    </div>
                    <div role='cell' className='td letter-cell-channel'>
                      {letter.channelUsed}
                    </div>
                    <div role='cell' className='td letter-cell-type'>
                      {letter.letterType && letter.letterType.name}
                    </div>
                  </div>
                );
              })}
            </CustomScrollbars>
          </div>
        </div>
        {filesState.loadingLetters ? <Spinner blocking /> : null}
      </div>
      <Button
        disabled={filesState.selectedLetter === null}
        onClick={viewLetter}
        loading={filesState.downloadingLetter}
      >
        View
      </Button>
    </div>
  );
});
