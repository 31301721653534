import React from 'react';
import './Checkbox.css';
import { IconCheckmark } from '../icons';

export type Props = {
  disabled?: boolean;
  id?: string;
  label?: string | JSX.Element;
  inverse?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  checked?: boolean;
  tooltip?: string;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const clickCheckbox = (e: React.FormEvent<HTMLDivElement>) => {
  e.currentTarget.getElementsByTagName('input')[0].click();
};

export const Checkbox = ({
  disabled,
  id,
  label,
  inverse,
  onChange,
  checked = false,
  tooltip,
  ...rest
}: Props) => {
  const classNames = () => {
    let classes: string = '';
    if (inverse) {
      classes += ' inverse';
    }
    if (checked) {
      classes += ' green';
    }
    if (disabled) {
      classes += ' disabled';
    }
    return classes;
  };
  return (
    label ? (
      <label
        tabIndex={-1}
        title={tooltip}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={'Checkbox' + classNames()}
      >
        <input id={id} type="checkbox" checked={checked} onChange={onChange} disabled={disabled} {...rest} />
        <IconCheckmark />
        <span className="text">{label}</span>
      </label>
    ) : (
      <div className={'Checkbox' + classNames()} onClick={clickCheckbox} title={tooltip}>
        <input id={id} type="checkbox" checked={checked} onChange={onChange} onClick={(e) => e.stopPropagation()} {...rest} />
        <IconCheckmark />
      </div>
    )
  )
};
